import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Rating from '@mui/material/Rating';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function ProductDetails({ productData, productFetching, isLoading, isFetching, productVarData, isVarLoading }) {
  const { languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()

  const [getShowSpecifications, setShowSpecifications] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const productDetails = showDetails ? productData?.details : productData?.details?.substring(0, 250)

  function checkDetails() {
    if (productData?.details === '<p></p>') {
      return false
    } else if (!productData?.details) {
      return false
    } else {
      return true
    }
  }

  function checkDetailsMore() {
    if (productData?.details === '<p></p>') {
      return false
    } else if (!productData?.details) {
      return false
    } else if (productData?.details === '<p>Full description not available</p>') {
      return false
    } else {
      return true
    }
  }

  function createImageTags(inputString) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let htmlString = inputString.replace(urlRegex, (url) => {
      if (/\.(png|jpg|jpeg|gif|bmp)$/i.test(url)) {
        return `
        <div style="display: flex; justify-content: center; padding: 10px 10px 10px 10px;">
        <img src="${url}" alt="Image" style="width: 200px; object-fit: contain" />
        </div>
        `;
      } else {
        return url;
      }
    });
    return htmlString;
  }


  let thisSpecificationList = productData?.specifications
  if (productVarData?.specifications) {
    thisSpecificationList = productVarData?.specifications
  }

  const thisSpecifications = productData?.store_id === 3 ? thisSpecificationList : productData?.specifications


  return (
    <>
      {isLoading ?
        <div style={{ paddingBottom: '10px', marginBottom: '5px' }}>
          <div ><Skeleton variant="rect" height={'15px'} width={'80%'} /></div>
          <div ><Skeleton variant="rect" height={'15px'} width={'70%'} /></div>
        </div>
        :
        <>

          {thisSpecifications?.length > 0 && <h5 className='cardTitle'>{t('specifications')}</h5>}

          {Array.isArray(thisSpecifications) ? (
            thisSpecifications?.slice(0, getShowSpecifications ? 100 : 8)?.map((item, index) => (
              <>
                {productData?.store_id === 3 ?
                  <div className="specification-card" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: '20px' }} key={index}>
                    <div style={{ fontWeight: 600 }}>{item.key}</div>
                    <div>{item.value}</div>
                  </div> :
                  <>
                    {Object.entries(item || []).map(([key, value], index) =>
                      <div className="specification-card" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: '20px' }} key={index}>
                        <div style={{ fontWeight: 600 }}>{key?.toString()}</div>
                        <div>{value?.toString()}</div>
                      </div>
                    )}
                  </>
                }
              </>
            ))
          ) : thisSpecifications && typeof thisSpecifications === 'object' ? (
            Object.entries(thisSpecifications?.slice(0, getShowSpecifications ? 100 : 8)).map(([key, value], index) => (
              <div className="specification-card" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: '20px' }} key={index}>
                <div style={{ fontWeight: 600 }}>{key}</div>
                <div>{value}</div>
              </div>
            ))
          ) : ''}

          {/* 

              {productData?.specifications?.slice(0, getShowSpecifications ? 100 : 8)?.map((item, i) =>
                <>
                  {productData?.store_id === 3 ?
                    <div className="specification-card" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: '20px' }} key={i}>
                      <div style={{ fontWeight: 600 }}>{item.key?.toString()}</div>
                      <div>{item.value?.toString()}</div>
                    </div> :
                    <>
                      {Object.entries(item || []).map(([key, value], index) =>
                        <div className="specification-card" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: '20px' }} key={index}>
                          <div style={{ fontWeight: 600 }}>{key?.toString()}</div>
                          <div>{value?.toString()}</div>
                        </div>
                      )}
                    </>
                  }
                </>
              )} */}

          {productData?.specifications?.length > 8 &&
            <div
              variant="outlined"
              onClick={(() => setShowSpecifications(getShowSpecifications ? false : true))}
              style={{ fontSize: '12px', cursor: 'pointer', color: '#8a3a19', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
              <div>{getShowSpecifications ? t('see_less') : `${t('see_all')} ${productData?.specifications?.length}`}</div>
              <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
            </div>
          }

        </>
      }
      {isLoading ?
        <Skeleton variant="rect" height={'15px'} width={'50%'} /> :
        <>
          {checkDetails() &&
            <>
              <h5 className='cardTitle'>{t('product_details')}</h5>
              {productData?.store_id === 3 ?
                <div dangerouslySetInnerHTML={{
                  __html: productDetails?.replace(/�/g, "'")
                }}>
                </div> :
                <>
                  {productData?.store_id !== 9 &&
                    <div dangerouslySetInnerHTML={{
                      __html: createImageTags(productDetails?.replace(/�/g, "'"))
                    }}>
                    </div>
                  }
                </>
              }
              {checkDetailsMore() &&
                <div
                  onClick={(() => setShowDetails(showDetails ? false : true))}
                  style={{ fontSize: '12px', cursor: 'pointer', color: '#8a3a19' }}>
                  {showDetails ? t('show_less') : t('show_more')}
                </div>
              }
            </>
          }
        </>

      }
      {productData?.top_reviews?.map((item, i) =>
        <div style={{ display: 'block', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px', marginBottom: '5px' }} key={i}>
          <div><strong>{item.title}</strong></div>
          <div style={{ color: '#898989' }}>{item.body}</div>
          <Rating
            name="simple-controlled"
            value={item.rating}
            style={{ fontSize: '1rem' }}
          />
        </div>
      )}
    </>
  );
}

export default ProductDetails;
