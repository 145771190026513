import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/joy/Button';
import { useSelector } from "react-redux";
function NotFound() {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  return (
    <div style={{
      padding: '100px 0px 20px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div className='container'>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-md-6" style={{ textAlign: 'center' }}>
            <div className="form-group" style={{ padding: '20px' }}>
              <div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 400, color: '#721c24' }}>Page not found</div>
              <br />
              <div className="form-group">
                <Button
                  variant='soft'
                  size='sm'
                  style={fontFamily}
                  component={Link}
                  to={`/`}
                >
                  <div style={{ textTransform: 'capitalize', fontWeight: 400 }}>
                    <small>Continue Shopping</small>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
