import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
import Host from '../../Components/Host';
import HelmetComponent from '../../Components/HelmetComponent';
function Careers() {

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <>
      <HelmetComponent title={'Careers'} />
      <div className="statisc-page">
        <div className="container static-container">
          <div className="row static-row">
            <div className="col-md-10">
              <div className="static-container-card" style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
              <h1 style={{ padding: '20px 0px 20px 0px', fontWeight: 300, fontSize: '1.4rem' }}>
                  {t('careers')}
                </h1>
                <p>Welcome to the Careers page of {Host?.domainName}, the leading e-commerce platform in the digital marketplace. We believe that our employees are the driving force behind our success, and we are constantly looking for talented individuals who are passionate about innovation, technology, and customer satisfaction. At {Host?.domainName}, you will have the opportunity to work with a dynamic team of professionals, collaborate on exciting projects, and contribute to shaping the future of online shopping.
                </p>
                <p>Join us on this incredible journey and unlock your full potential in a fast-paced, challenging, and rewarding environment. Explore our current job openings and take the first step towards an amazing career with {Host?.domainName}.</p>
                <p>Sorry! We no longer have any vacant positions for hiring. </p>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Careers;

