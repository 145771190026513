const thwiftyImages = [
  {
    link: 'coleman',
    image: 'https://cdn.ebtida.com/1704191487441-Coleman-3.jpg',
  },
  {
    link: 'drunk elephant',
    image: 'https://cdn.ebtida.com/1704191472445-Drunk-elephant-2.jpg',
  },
  {
    link: 'stanley',
    image: 'https://cdn.ebtida.com/1704191453120-Stanley-3.jpg',
  },
  {
    link: 'leatherman',
    image: 'https://cdn.ebtida.com/1706872924067-LEATHERMAN.jpg',
  },
  {
    link: 'dr squatch',
    image: 'https://cdn.ebtida.com/1704191481634-Dr-Squatch-2.jpg'
  }
]

const domain = 'thwifty.com'
const domainName = 'Thwifty'
const domainLogo = 'https://cdn.ebtida.com/1703526807565-thwifty.png'
const bgColor = '#fe7f4a'
const favicon = 'https://cdn.ebtida.com/1703526815046-favicon-thwifty.png'
const email = 'help@thwifty.com'
const metaTitle = 'Thwifty: Your One-Stop Shop for Affordable Deals and Quality Products'
const metaDescription = 'Experience the best shopping experience at affordable prices with Thwifty. Discover a wide range of products from top brands and enjoy incredible savings. Shop smart and save big with Thwifty today'
const headerBg = '#fcfcfc82'
const subMenu = '#fcfcfc82'
const bannesImages = thwiftyImages
const buttonColor = '#f7f7f7'
const address = 'Fahidi Towers, 1502A, 15th Floor <br />Bur Dubai, Khalid Bin Waleed Road,<br /> Dubai, UAE'
const phone = '+971 4 836 9553'

const Host = {
  domain: domain,
  domainName: domainName,
  domainLogo: domainLogo,
  bgColor: bgColor,
  favicon: favicon,
  email: email,
  metaTitle: metaTitle,
  metaDescription: metaDescription,
  headerBg: headerBg,
  subMenu: subMenu,
  bannesImages: bannesImages,
  buttonColor: buttonColor,
  address: address,
  phone: phone
}


export default Host;