const languages = [
  {
    code: 'en',
    name: 'English',
    dir: 'ltr',
    country_code: 'US',
    lang: 'en',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'AE',
    lang: 'ar',
  }
]

export default languages