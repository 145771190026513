import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';

import { loginApi } from '../services/login';
import { paymentsApi } from '../services/payments';
import { membershipApi } from '../services/membership';
import { inquiryApi } from '../services/inquiries';
import { subscriptionApi } from '../services/subscriptions';
import { searchApi } from '../services/search';
import { productApi } from '../services/product';
import { homepageApi } from '../services/homepage';
import { cartApi } from '../services/cart';
import { trackApi } from '../services/track_order';
import authReducer from "../Reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [membershipApi.reducerPath]: membershipApi.reducer,
    [inquiryApi.reducerPath]: inquiryApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [homepageApi.reducerPath]: homepageApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [trackApi.reducerPath]: trackApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loginApi.middleware).concat(paymentsApi.middleware).concat(membershipApi.middleware).concat(inquiryApi.middleware).concat(subscriptionApi.middleware).concat(searchApi.middleware).concat(productApi.middleware).concat(homepageApi.middleware).concat(cartApi.middleware).concat(trackApi.middleware)
})

setupListeners(store.dispatch)
