import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetOrderQuery, useShowOrderQuery } from '../../../services/membership';
import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Host from '../../../Components/Host';
import { Tag } from "antd";
import OrdersList from './OrdersList';
import SideMenu from '../SideMenu';
import CircularProgress from '@mui/material/CircularProgress';
import formatPrice from '../../../Components/formatPrice';
var moment = require('moment');
function Order() {
  const { userId, userToken, userEmail, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { orderNumber } = useParams();
  if (!userEmail && !userToken) {
    history.push('/')
  }
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24
  const membership = useShowOrderQuery({
    userId: userId,
    orderNumber: orderNumber,
    userToken: userToken,
    domain: Host?.domain,
    countryCode: countryCode
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orderData = membership?.data?.order

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="Account-page tabel_cards">
      <div className="container">
        <div className='account-section'>
          <SideMenu />
          <div className='op-section' style={{ width: '100%' }}>
            <div className="row" style={{ justifyContent: 'center' }}>
              {membership?.isLoading ?
                <div className='col-md-12' style={{ textAlign: 'center' }}>
                  <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                </div> :
                <>
                  <div className='col-md-12'>
                    <div className="ap-title">Order Item Details</div>
                    <div className='row'>
                      <div className='col-md-8'>
                        <div className='op-card'>
                          <OrdersList orders={orderData} />
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className='op-card'>
                          <div>
                            <h4 className="ap-title" style={{ textAlign: 'left', padding: '0px 0px 0px 0px' }}>Order Summary</h4>
                          </div>
                          <br />
                          <div className='o-summary-l'>
                            <span>{t('order_placed_on')}: </span>
                            <small>{moment(orderData?.createdAt).format("MMM Do YYYY")}</small>
                          </div>
                          <div className='o-summary-l'>
                            <span>{t('order_status')}: </span>
                            {orderData?.states === 'cancelled' ? <span className='badge badge-danger'>Cancelled</span> :
                              <small>
                                {orderData?.status ?
                                  <span className='badge badge-success'>Confirmed</span> :
                                  <span className='badge badge-warning'>Payment pending</span>
                                }
                              </small>
                            }
                          </div>
                          <div className='o-summary-l'>
                            <span>{t('order_number')}: </span>
                            <small>{orderData?.order_number}</small>
                          </div>
                          <div className='o-summary-l'>
                            <span>{t('total_items')}: </span>
                            <small>{orderData?.order_items?.length}</small>
                          </div>
                          <div className='o-summary-l'>
                            <span>{t('subtotal')}: </span>
                            <small>{formatPrice(orderData?.amount, orderData?.currency, 0)}</small>
                          </div>
                          {orderData?.coupon?.coupon_code &&
                            <div className='o-summary-l'>
                              <span>{t('discount')}: </span><small>-{formatPrice(orderData?.coupon?.discount, orderData?.currency, 0)}</small>
                            </div>
                          }
                          <div className='o-summary-l'>
                            <span>{t('shipping_fee')}:</span>
                            <span>{orderData?.currency} {orderData?.shipping_price === 0 ? 'FREE' : `+${orderData?.shipping_price}`}</span>
                          </div>
                          <div className='o-summary-l'>
                            <span>{t('total')}: </span>
                            <span>
                              {formatPrice(orderData?.amount, orderData?.currency, orderData?.shipping_price)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Order;