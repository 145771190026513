import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Payment'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getAllPayments: builder.query({
      query: (data) => ({
        url: `api/payments`,
        method: 'GET',
        headers: {
          'X-Country-Code': data.countryCode,
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Payment'],
    }),

    createCustomer: builder.mutation({
      query: (data) => {
        return {
          url: `api/payments/${data.userId}`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    defaultPayments: builder.mutation({
      query: (data) => {
        return {
          url: `api/payments`,
          method: 'PUT',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),


    removePayments: builder.mutation({
      query: (data) => {
        return {
          url: `api/payments`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    cancelMembership: builder.mutation({
      query: (data) => {
        return {
          url: `api/payments/${data.user_id}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    checkout: builder.mutation({
      query: (data) => {
        return {
          url: `api/checkout/${data.userId}`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    guestCheckout: builder.mutation({
      query: (data) => {
        return {
          url: `api/checkout`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    applePayCheckout: builder.mutation({
      query: (data) => {
        return {
          url: `api/checkout/apple/pay`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),



    purchase: builder.mutation({
      query: (data) => {
        return {
          url: `api/purchase`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    purchaseConfirm: builder.mutation({
      query: (data) => {
        return {
          url: `api/purchase/confirm`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),


    tapCheckout: builder.mutation({
      query: (data) => {
        return {
          url: `api/t/checkout`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    tapCheckoutConfirm: builder.mutation({
      query: (data) => {
        return {
          url: `api/t/checkout/confirm`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Payment'],
    }),

    getAllTapPayments: builder.query({
      query: (data) => ({
        url: `api/t/checkout/${data.customer}`,
        method: 'GET',
        headers: {
          'X-Country-Code': data.countryCode,
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'domain': data.domain,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Payment'],
    }),



  }),

})

export const {
  useGetAllPaymentsQuery,
  useCreateCustomerMutation,
  useRemovePaymentsMutation,
  useDefaultPaymentsMutation,
  useCancelMembershipMutation,
  useCheckoutMutation,
  useGuestCheckoutMutation,
  useApplePayCheckoutMutation,
  usePurchaseMutation,
  usePurchaseConfirmMutation,
  useTapCheckoutMutation,
  useTapCheckoutConfirmMutation,
  useGetAllTapPaymentsQuery,
} = paymentsApi