import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetShippingAddressesQuery } from '../../../services/login';
import { useSelector } from "react-redux";
import 'react-loading-skeleton/dist/skeleton.css'
import HelmetComponent from '../../../Components/HelmetComponent';
import SideMenu from '../SideMenu';
import CircularProgress from '@mui/material/CircularProgress';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

function ShippingAddresses() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  const ShippingInfo = useGetShippingAddressesQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const ShippingData = ShippingInfo?.data?.shipping_addresses


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])



  return (
    <>
      <HelmetComponent title={'Payment methods'} />
      <div className="Account-page tabel_cards">
        <div className="container">
          <div className='account-section'>
            <SideMenu />
            <div className='op-section' style={{ width: '100%' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                {!ShippingInfo?.isLoading &&
                  <div className="col-md-12">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <PushPinOutlinedIcon style={{ fontSize: '1.5rem' }} />
                      <div className='ap-title' style={{ fontSize: '1.2rem', margin: '0px 10px' }}>
                        {t('Shipping Addresses')}
                      </div>
                    </div>
                  </div>
                }
                {ShippingInfo?.isLoading &&
                  <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                  </div>
                }
                {ShippingData?.map((item, i) =>
                  <div className="col-md-7" key={i}>
                    <div style={{ border: '1px solid #e5e5e5', borderRadius: '10px', background: '#fff', padding: '20px', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                            <div className="cart-page-text">
                              <div style={{ width: '100%' }}>
                                <div style={{ fontSize: '1.2rem', fontWeight: 300 }}>{item.name}, {item.address}, {item.street}, {item.city}, {item.country_code}</div>
                                <div style={{ fontSize: '1.2rem', fontWeight: 300 }}>{item.phone}</div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {ShippingData?.length === 0 &&
                  <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <div className="no-data-found"> No data found</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingAddresses;