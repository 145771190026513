import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { useGetUserDataQuery, useGetShippingAddressesQuery } from '../../services/login';
import { usePurchaseMutation } from '../../services/payments';
import Host from '../../Components/Host';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addCheckoutMethod } from "../../Reducers/authReducer";
import { useFetchQuery } from '../../services/cart';
import 'react-phone-number-input/style.css'
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function GuestPay({ guestShippment, purchaseconfrmresult }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, isPurchase } = useSelector((state) => state.auth);
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [getSelectShipmentAddress, setSelectShipmentAddress] = React.useState(false);
  const [getSelectShipmentAddressId, setSelectShipmentAddressId] = React.useState();
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const couponCode = new URLSearchParams(location.search).get('coupon') || null
  const { data: cartInfo, isLoading, isFetching, refetch } = useFetchQuery({ coupon: couponCode, userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const cartData = cartInfo?.cart
  const thisToken = cartInfo?.token
  const couponData = cartInfo?.coupon
  const ShippingInfo = useGetShippingAddressesQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const ShippingData = ShippingInfo?.data?.shipping_addresses
  const [createpurchase, purchaseresult] = usePurchaseMutation()
  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])

  const [isSelected, setSelected] = useState(false);

  function proceedDefaultPayment() {
    setSelected(true)
    createpurchase({
      shipping_address: guestShippment,
      language: languageCode,
      country: countryCode,
      amount: couponData ? Number(TotalPrice) - Number(couponData?.discount) : Number(TotalPrice),
      shipping: Number(userData?.contant?.standard_delivery),
      currency: cartData?.[0]?.prices?.currency,
      delivery_days: Number(userData?.contant?.delivery_days),
      name: guestShippment?.name,
      email: guestShippment?.email,
      products: cartData,
      cartToken: cartToken,
      domainName: Host?.domainName,
      domain: window.location.hostname,
      domainEmail: Host?.email,
      userEmail: guestShippment?.email,
      countryCode: countryCode,
      token: thisToken,
      coupon: couponData ? couponData : null,
      payby_checkout: 'payby_checkout',
      payment_type: 'payby_apple'
    })
  }

  useEffect(() => {
    if (!getSelectShipmentAddressId) {
      if (ShippingData?.length === 0) {
        setSelectShipmentAddress(false)
      } else {
        setSelectShipmentAddress(true)
        setSelectShipmentAddressId(ShippingData?.[0]?.id)
      }
    }
  }, [getSelectShipmentAddress, ShippingData])

  const [getTestLoading, setTestLoading] = useState(false);

  useEffect(() => {
    if (purchaseresult?.isSuccess) {
      dispatch(addCheckoutMethod({ isPurchase: purchaseresult?.data?.purchase_token }))
      setTestLoading(true)
      const tokenUrl = purchaseresult?.data?.data?.body?.interActionParams?.tokenUrl
      window.location.href = tokenUrl;
      setTestLoading(false)
    }
  }, [purchaseresult, purchaseresult?.isSuccess, getTestLoading])

  function getThisLoading(loading1, loading2) {
    if (loading1) {
      return true
    } else if (loading2) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {purchaseconfrmresult?.error?.data?.error &&
        <small style={{ textAlign: 'center', color: 'tomato', fontSize: '12px' }}>
          {purchaseconfrmresult?.error?.data?.error}
        </small>
      }
      {purchaseresult?.error?.data?.error &&
        <small style={{ textAlign: 'center', color: 'tomato', fontSize: '12px' }}>
          {purchaseresult?.error?.data?.error}
        </small>
      }
      <div
        onClick={() =>  guestShippment?.email && proceedDefaultPayment()}
        style={{ background: '#fcfcfc', padding: '10px', border: '1px solid #dddddd', borderRadius: 8, fontSize: '14px', display: 'flex', flexWrap: 'wrap', marginBottom: '10px', justifyContent: 'space-between', cursor: 'pointer' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
            </svg>
            <span style={{ margin: '0px 5px' }}>Apple Pay</span>
          </span>
        </div>
        {getThisLoading(purchaseconfrmresult?.isLoading, purchaseresult?.isLoading) ?
          <CircularProgress style={{ width: '1.3rem', height: '1.3rem', color: '#fe7f4a' }} /> :
          <div style={{ fontSize: '11px', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
          <span>Continue with Apple</span>
          <ArrowForwardIcon style={{ fontSize: '1rem', margin: '0px 5px' }} />
        </div>
        }
      </div>
    </>
  );
}

export default GuestPay;
