import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetOrderQuery } from '../../../services/membership';
import { useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Host from '../../../Components/Host';
import OrdersList from './OrdersList';
import SideMenu from '../SideMenu';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/joy/Button';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
var moment = require('moment');
function Membership() {
  const { userId, userToken, userEmail, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  if (!userEmail && !userToken) {
    history.push('/')
  }
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const membership = useGetOrderQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    domain: Host?.domain,
    countryCode: countryCode
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const membershipData = membership?.data?.order_items
  const pagination = membership?.data?.pagination

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [page])

  return (
    <div className="Account-page tabel_cards">
      <div className="container">
        <div className='account-section'>
          <SideMenu />
          <div className='op-section' style={{ width: '100%' }}>
            <div className="row" style={{ justifyContent: 'center' }}>

              {membership?.isLoading ?
                <div className='col-md-12' style={{ textAlign: 'center' }}>
                  <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                </div> :
                <>
                  <div className="col-md-12">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <LocalMallOutlinedIcon style={{ fontSize: '1.5rem' }} />
                      <div className='ap-title' style={{ fontSize: '1.2rem', margin: '0px 10px' }}>
                        {t('My Orders')}
                      </div>
                    </div>
                  </div>
                  {membershipData?.map((item, i) =>
                    <div className='col-md-10' key={i}>
                      <div className='op-card'>
                        <div className='op-card-header'>
                          <OrdersList orders={item} />
                          <div className='op-o-info'>
                            <div style={{ marginBottom: '6px' }}>
                              <span style={{ fontWeight: 500 }}>{t('order_number')}: &nbsp;</span>
                              {item?.order_number}
                            </div>
                            <div style={{ marginBottom: '6px' }}>
                              <span style={{ fontWeight: 500 }}>{t('total_items')}:&nbsp;</span>
                              {item?.order_items?.length}
                            </div>
                            <div style={{ marginBottom: '6px' }}>
                              <span style={{ fontWeight: 500 }}>{t('order_status')}: &nbsp;</span>
                              {item?.states === 'cancelled' ? <span className='badge badge-danger'>Cancelled</span> :
                                <>
                                  {item?.status ?
                                    <span className='badge badge-success'>Confirmed</span> :
                                    <span className='badge badge-warning'>Payment pending</span>
                                  }
                                </>
                              }
                            </div>
                            <div style={{ marginBottom: '6px' }}>
                              <span style={{ fontWeight: 500 }}>{t('order_placed_on')}: &nbsp;</span>{moment(item?.createdAt).format("MMM Do YYYY")}
                            </div>
                            <div style={{ marginBottom: '6px' }}>
                              <Button
                                style={{ fontSize: '14px', fontWeight: 500 }}
                                component={Link}
                                variant='outlined'
                                color='warning'
                                size='sm'
                                to={`/account/order/${item?.order_number}`} >
                                {t('Track')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              }
              {!membership?.isLoading &&
                <div className="col-md-10">
                  {membershipData?.length > 0 &&
                    <div className="table_search_card">
                      <Pagination
                        dir='ltr'
                        size='medium'
                        style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'center', padding: '0px', direction: 'ltr' }}
                        page={parseInt(page)}
                        count={parseInt(pagination?.totalPages)}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        disabled={membership?.isLoading ? true : false}
                        renderItem={(item) => (
                          <PaginationItem
                            component={Link}
                            to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  }
                </div>
              }
              {membershipData?.length === 0 &&
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <div className="no-data-found"> {t('No Orders Found')}</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membership;