import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Search'],
  refetchOnMountOrArgChange: 1,
  keepUnusedDataFor: 86400000,
  endpoints: (builder) => ({
    getSearchQuery: builder.query({
      query: (data) => {
        return {
          url: `api/search/${data.query}?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Search'],
    }),


    getStoreSearchQuery: builder.query({
      query: (data) => {
        return {
          url: `api/store/${data.store_id}?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Search'],
    }),

    getStoreQuerySearchQuery: builder.query({
      query: (data) => {
        return {
          url: `api/store/query/${data.query}?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Search'],
    }),

    getStoreDeals: builder.query({
      query: (data) => {
        return {
          url: `api/warehouse/deals?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 86400000,
      providesTags: ['Search'],
    }),


  }),

})

export const { useGetSearchQueryQuery, useGetStoreSearchQueryQuery, useGetStoreQuerySearchQueryQuery, useGetStoreDealsQuery } = searchApi