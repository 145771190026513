import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  userId: localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '',
  userEmail: localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : '',
  userToken: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : '',
  cartToken: localStorage.getItem("cartToken") ? localStorage.getItem("cartToken") : '',
  userName: localStorage.getItem("userName") ? localStorage.getItem("userName") : '',
  isMember: localStorage.getItem("isMember") ? localStorage.getItem("isMember") : false,
  isPaymentMethod: localStorage.getItem("isPaymentMethod") ? localStorage.getItem("isPaymentMethod") : false,
  isCustomer: localStorage.getItem("isCustomer") ? localStorage.getItem("isCustomer") : false,
  isInquiry: localStorage.getItem("isInquiry") ? localStorage.getItem("isInquiry") : false,
  isPurchase: localStorage.getItem("isPurchase") ? localStorage.getItem("isPurchase") : '',
  languageCode: localStorage.getItem("languageCode") ? localStorage.getItem('languageCode') : 'en',
  countryCode: localStorage.getItem("countryCode") ? localStorage.getItem("countryCode") : '',
  subscription: localStorage.getItem("subscription") ? JSON.parse(localStorage.getItem("subscription")) : '',
  guestShippment: localStorage.getItem("guestShippment") ? JSON.parse(localStorage.getItem("guestShippment")) : '',
  guestPayment: localStorage.getItem("guestPayment") ? JSON.parse(localStorage.getItem("guestPayment")) : '',
  selectedPass: localStorage.getItem("selectedPass") ? JSON.parse(localStorage.getItem("selectedPass")) : false,
  isUser: localStorage.getItem("isUser") ? localStorage.getItem("isUser") : '',
  isUserLoading: localStorage.getItem("isUserLoading") ? localStorage.getItem("isUserLoading") : '',
  productImg: localStorage.getItem("productImg") ? localStorage.getItem("productImg") : '',
  domain: localStorage.getItem("domain") ? localStorage.getItem("domain") : '',
  domainName: localStorage.getItem("domainName") ? localStorage.getItem("domainName") : '',
  domainLogo: localStorage.getItem("domainLogo") ? localStorage.getItem("domainLogo") : '',
  bgColor: localStorage.getItem("bgColor") ? localStorage.getItem("bgColor") : '',
  viewedProducts: localStorage.getItem("viewedProducts") ? JSON.parse(localStorage.getItem("viewedProducts")) : '',
  localProducts: localStorage.getItem("localProducts") ? JSON.parse(localStorage.getItem("localProducts")) : [],
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addToCart(state, action) {
      state.userId = action.payload?.userId
      state.userEmail = action.payload?.userEmail
      state.userToken = action.payload?.userToken
      state.userName = action.payload?.userName
      state.isMember = action.payload?.isMember
      state.isPaymentMethod = action.payload?.isPaymentMethod
      state.isCustomer = action.payload?.isCustomer
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken);
      localStorage.setItem("userEmail", state.userEmail);
      localStorage.setItem("userName", state.userName);
      localStorage.setItem("isMember", state.isMember);
      localStorage.setItem("isPaymentMethod", state.isPaymentMethod);
      localStorage.setItem("isCustomer", state.isCustomer);
    },


    addToInquiry(state, action) {
      state.isInquiry = action.payload?.isInquiry
      localStorage.setItem("isInquiry", state.isInquiry);
    },

    addCartToken(state, action) { 
      state.cartToken = action.payload?.cartToken 
      localStorage.setItem("cartToken", state.cartToken); 
    },

    removeToCart(state, action) {
      state.userId = ''
      state.userEmail = ''
      state.userToken = ''
      state.userName = ''
      state.isMember = false
      state.isPaymentMethod = false
      state.isCustomer = false
      state.isPurchase = ''
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userToken", state.userToken);
      localStorage.setItem("userEmail", state.userEmail);
      localStorage.setItem("userName", state.userName);
      localStorage.setItem("isMember", state.isMember);
      localStorage.setItem("isPaymentMethod", state.isPaymentMethod);
      localStorage.setItem("isCustomer", state.isCustomer);
      localStorage.setItem("isPurchase", state.isPurchase);
    },

    addToLanguage(state, action) {
      state.languageCode = action.payload?.languageCode
      state.countryCode = action.payload?.countryCode
      localStorage.setItem("languageCode", state.languageCode)
      localStorage.setItem("countryCode", state.countryCode)
    },


    addHost(state, action) {
      state.domain = action.payload?.domain
      state.domainName = action.payload?.domainName
      state.domainLogo = action.payload?.domainLogo
      state.bgColor = action.payload?.bgColor
      localStorage.setItem("domain", state.domain)
      localStorage.setItem("domainName", state.domainName)
      localStorage.setItem("domainLogo", state.domainLogo)
      localStorage.setItem("bgColor", state.bgColor)
    },

    addProductImg(state, action) {
      state.productImg = action.payload?.productImg
      localStorage.setItem("productImg", state.productImg)
    },

    removeToLanguage(state, action) {
      state.languageCode = ''
      localStorage.setItem("languageCode", state.languageCode)
    },

    addCheckoutMethod(state, action) {
      state.isMember = action.payload?.isMember
      state.isPaymentMethod = action.payload?.isPaymentMethod
      state.isCustomer = action.payload?.isCustomer
      state.isPurchase = action.payload?.isPurchase
      localStorage.setItem("isMember", state.isMember);
      localStorage.setItem("isPaymentMethod", state.isPaymentMethod);
      localStorage.setItem("isCustomer", state.isCustomer);
      localStorage.setItem("isPurchase", state.isPurchase);
    },

    addSubscription(state, action) {
      state.subscription = action.payload?.subscription
      localStorage.setItem("subscription", JSON.stringify(state.subscription));
    },

    addGuestShippment(state, action) {
      state.guestShippment = action.payload?.guestShippment
      localStorage.setItem("guestShippment", JSON.stringify(state.guestShippment));
    },
    addGuestPayment(state, action) {
      state.guestPayment = action.payload?.guestPayment
      localStorage.setItem("guestPayment", JSON.stringify(state.guestPayment));
    },

    addLocalProducts(state, action) {
      state.localProducts = action.payload?.localProducts
      localStorage.setItem("localProducts", JSON.stringify(state.localProducts));
    },

    addSelectedSevenDayaPass(state, action) {
      state.selectedPass = action.payload?.selectedPass
      localStorage.setItem("selectedPass", state.selectedPass);
    },

    addUserToState(state, action) {
      state.isUser = action.payload?.isUser
      state.isUserLoading = action.payload?.isUserLoading
      localStorage.setItem("isUser", state.isUser);
      localStorage.setItem("isUserLoading", state.isUserLoading);
    },
    removeUserToState(state, action) {
      state.isUser = ''
      state.isUserLoading = ''
      localStorage.setItem("isUser", state.isUser);
      localStorage.setItem("isUserLoading", state.isUserLoading);
    },

    addViewedProducts(state, action) {
      state.viewedProducts = action.payload?.viewedProducts
      localStorage.setItem("viewedProducts", JSON.stringify(state.viewedProducts));
    },

  },
});

export const { addToCart, removeToCart, addToLanguage, removeToLanguage, addCheckoutMethod, addSubscription, addSelectedSevenDayaPass, addUserToState, removeUserToState, addProductImg, addCartToken, addGuestShippment, addGuestPayment, addToInquiry, addViewedProducts, addLocalProducts } = authReducer.actions;

export default authReducer.reducer;
