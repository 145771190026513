import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useGetUserDataQuery, useUpdateUserDetailsMutation } from '../../../services/login';
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Host from '../../../Components/Host';
import HelmetComponent from '../../../Components/HelmetComponent';
import SideMenu from '../SideMenu';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/joy/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
const md5 = require('md5');
function Settings() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode, countryCode } = useSelector((state) => state.auth);

  const history = useHistory();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const getUserData = userData?.data?.user;
  const [updateUserD, updateUserDResult] = useUpdateUserDetailsMutation()

  const [getModal, setModal] = useState(false);
  const [getName, setName] = useState('');
  const [getPhone, setPhone] = useState('');

  function updateUserDetails(data) {
    setModal(data.status)
    setName(data.name)
    setPhone(data.phone)
  }
  function closeModal() {
    setModal(false)
  }

  const updateData = {
    name: getName,
    phone: getPhone,
    userId: userId,
    userToken: userToken,
    country: countryCode,
    update_details: true
  }

  const [getPasswordModal, setPasswordModal] = useState(false);

  function changePassword() {
    setPasswordModal(true)
  }
  function closePasswordModal() {
    setPasswordModal(false)
  }

  async function changePasswordForm(data) {
    await updateUserD({
      old_password: md5(data.old_password),
      new_password: md5(data.new_password),
      userId: userId,
      userToken: userToken,
      country: countryCode,
      update_password: true
    })
  }

  useEffect(() => {
    if (updateUserDResult?.isSuccess) {
      setModal(false)
      setPasswordModal(false)
      alert('Successfully updated!')
    }
  }, [updateUserDResult]);


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily_Text = {
    fontFamily: fontFamilyForAll,
    textAlign: 'start'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  return (
    <>
      <HelmetComponent title={'Settings'} />
      <div className="Account-page tabel_cards">
        <Modal style={fontFamily_Text} className="refund-modal" show={getModal} onHide={closeModal} animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{
                  textAlign: 'center',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1rem'
                }}>{t('update_profile')}</h5>
              </div>
            </div>
            {updateUserDResult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', width: '100%' }}>
                <span className="alert alert-danger" style={{ width: '100%', textAlign: 'center' }}>{updateUserDResult?.error?.data?.error}</span>
              </div>
            }
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('name')}</label>
                  <input type="text" className="form-control dlrdflInput" defaultValue={getUserData?.name} onChange={(e) => setName(e.target.value)} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('phone')}</label>
                  <input type="number" className="form-control dlrdflInput" defaultValue={getUserData?.phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Button
                    disabled={updateUserDResult?.isLoading}
                    style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', height: '45px', textTransform: 'capitalize' }}
                    onClick={() => !updateUserDResult.isLoading && updateUserD(updateData)}>
                    <span style={fontFamily}>{updateUserDResult.isLoading ? 'loading' : t('update')}</span>
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal style={fontFamily_Text} className="refund-modal" show={getPasswordModal} onHide={closePasswordModal} animation={false}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closePasswordModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{
                  textAlign: 'center',
                  padding: '10px',
                  fontWeight: 400,
                  fontSize: '1rem'
                }}>{t('change_password')}</h5>
              </div>
            </div>
            {updateUserDResult?.error?.data?.error &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', width: '100%' }}>
                <span className="alert alert-danger" style={{ width: '100%', textAlign: 'center' }}>{updateUserDResult?.error?.data?.error}</span>
              </div>
            }
            <form onSubmit={handleSubmit(changePasswordForm)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>{t('current_password')}</label>
                    <input type="password" className="form-control dlrdflInput"
                      id="old_password"
                      aria-invalid={errors.old_password ? "true" : "false"}
                      {...register('old_password', { required: true })} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>{t('new_password')}</label>
                    <input
                      type="password"
                      className="form-control dlrdflInput"
                      id="new_password"
                      aria-invalid={errors.new_password ? "true" : "false"}
                      {...register('new_password', { required: true, minLength: 8, maxLength: 16 })} />
                    {errors.new_password && errors.new_password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                    {errors.new_password && errors.new_password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>new_password should be maximum 16 digits</span>}
                    {errors.new_password && errors.new_password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>new_password should be minimum 8 digits</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <Button
                      style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', height: '45px', textTransform: 'capitalize' }}
                      disabled={updateUserDResult.isLoading}
                      type="submit" >
                      <span style={fontFamily}>{updateUserDResult.isLoading ? 'loading' : t('update')}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className='account-section'>
            <SideMenu />
            <div className='op-section' style={{ width: '100%' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                {userData?.isLoading ?
                  <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
                  </div>
                  :
                  <>
                    <div className='col-md-12'>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ListAltOutlinedIcon style={{ fontSize: '1.5rem' }} />
                        <div className='ap-title' style={{ fontSize: '1.2rem', margin: '0px 10px' }}>
                          {t('profile_details')}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className='op-card' style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1 }}>
                          <Button
                            style={{ fontWeight: 400, textTransform: 'capitalize' }}
                            onClick={(() => updateUserDetails({
                              status: true,
                              name: getUserData?.name,
                              phone: getUserData?.phone
                            }))}
                            color="warning"
                            variant="outlined"
                            size='sm'>
                            <EditOutlinedIcon style={{ fontSize: '1rem' }} />
                          </Button>
                        </div>
                        <div className="row-card">
                          <div style={{ fontSize: '1.2rem' }}><span style={{ fontWeight: 300 }}>{getUserData?.name}</span></div>
                        </div>
                        <div className="row-card">
                          <div style={{ fontSize: '1.2rem' }}><span style={{ fontWeight: 300 }}>{getUserData?.email}</span></div>
                        </div>
                        <div className="row-card">
                          <div style={{ fontSize: '1.2rem' }}><span style={{ fontWeight: 300 }}>{getUserData?.phone ? getUserData?.phone : ''}</span></div>
                        </div>
                      </div>

                      <div style={{ marginTop: '10px' }}>
                        <Button
                          style={{ fontWeight: 400, textTransform: 'capitalize' }}
                          onClick={(() => changePassword())}
                          color="warning"
                          variant="outlined"
                          size='sm'>
                          {t('change_password')}
                        </Button>
                      </div>

                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;

