import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cartApi = createApi({
  reducerPath: 'cartApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Cart'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (data) => ({
        url: `api/cart/${data.cartToken}${data.coupon ? `?coupon=${data.coupon}` : ''}`,
        method: 'GET',
        headers: {
          'X-Country-Code': data.countryCode,
          'X-Cart-Token': data.cartToken,
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      keepUnusedDataFor: 86400000,
      providesTags: ['Cart'],
    }),



    update: builder.mutation({
      query: (data) => {
        return {
          url: `api/cart/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Cart-Token': data.cartToken,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Cart'],
    }),


    delete: builder.mutation({
      query: (data) => {
        return {
          url: `api/cart/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Cart-Token': data.cartToken,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Cart'],
    }),

    create: builder.mutation({
      query: (data) => {
        return {
          url: `api/cart/${data.cartToken}`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Cart-Token': data.cartToken,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Cart'],
    }),


    fetchCoupon: builder.mutation({
      query: (data) => {
        return {
          url: `api/cart`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Cart-Token': data.cartToken,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Cart'],
    }),


  }),

})

export const {
  useFetchQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  useFetchCouponMutation,
} = cartApi