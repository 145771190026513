import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import Host from '../../Components/Host';
import HelmetComponent from '../../Components/HelmetComponent';
import { useGetUserDataQuery, useGetShippingAddressesQuery, useCreateShippingAddressMutation, useCancelAddressMutation } from '../../services/login';
function PaymentPolicy() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const [getSearchId, setSearchId] = useState("")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })


  const myElementRef = React.useRef(null);
  const myElementRefs = React.useRef(null);
  const myContact = React.useRef(null);

  const myPayment = React.useRef(null);
  const myBilling = React.useRef(null);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash === 'returns') {
        myElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'shipping') {
        myElementRefs.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'contact') {
        myContact.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'billing') {
        myBilling.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'payment') {
        myPayment.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [window, window.location.hash]);

  return (
    <>
      <HelmetComponent title={'Payment Policy'} />
      <div className="statisc-page">
        <div className="container static-container">
          <div className="row static-row">
            <div className="col-md-10">
              <div className="static-container-card"
                style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
                <h3 style={{ padding: '20px 0px 20px 0px', fontWeight: 300, fontSize: '1.4rem' }}>
                  {t('Payment Policy')}
                </h3>

                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  At {Host?.domainName}, we take the security of our customers' personal information and payment details very seriously. This policy outlines the payment methods we accept, how we process payments, and the measures we take to ensure the security of your information.
                </p>

                <h4>Payment Methods</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  We accept the following payment methods:
                </p>
                <ul>
                  <li>Credit and Debit Cards (Visa, Mastercard, American Express)</li>
                </ul>

                <h4>Payment Processing</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  All payments made on our website are processed securely using industry-standard encryption technologies. We do not store any credit or debit card details on our servers, and all payment information is transmitted securely to our payment processor.
                </p>


                <h4>Security Measures</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  We take the following security measures to protect our customers' personal information and payment details:
                </p>
                <ul>
                  <li>We use secure servers to store personal information and payment details.</li>
                  <li>We use SSL encryption to protect data transmitted between our website and our customers' browsers.</li>
                  <li>We use two-factor authentication to secure access to our servers and databases.</li>
                  <li>We regularly review and update our security policies and procedures to ensure they are up to date and effective.</li>
                </ul>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the security of your information.</p>

                <h4>Changes to This Payment and Security Policy</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  We reserve the right to update or modify this payment and security policy at any time. We will notify you of any changes by posting the updated policy on our website. Your continued use of our website after any changes indicates your acceptance of the updated policy.
                </p>


                <h4>Contact Us</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  If you have any questions or concerns about our payment and security policy, please contact us via our
                  <Link to="/contact-us">contact page</Link>.
                </p>


                <p>&nbsp;</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPolicy;

