import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import './index.scss';
import { Menu as Menus } from 'antd';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { removeToCart, removeUserToState, addSelectedSevenDayaPass } from "../../../Reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

function SideMenu(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  function handleClick(e) {
    console.log('', e);
  }

  // const [collapsed, setStatesss] = useState(false)
  // function toggleCollapsed() {
  //   setStatesss(!collapsed)
  // };

  function setNevActive(path) {
    if (window.location.pathname?.includes(path)) {
      return true
    } else {
      return false
    }
  }

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };

  function userLogout() {
    dispatch(removeToCart());
    dispatch(removeUserToState());
    dispatch(addSelectedSevenDayaPass({ selectedPass: false }));
    history.push("/");
  }

  useEffect(() => {
    if (isMobcreen) {
      setStatesss(true)
    } else {
      setStatesss(false)
    }
  }, [isMobcreen]);


  const thisStyle = {
    position: 'absolute',
    right: 0,
    left: 0,
    zIndex: 111,
    height: '100%',
    width: '240px'
  }

  const thisStylecc = {
    position: 'relative',
  }

  return (
    <div className="account-side-menu-section" style={isMobcreen && !collapsed ? thisStyle : thisStylecc}>
      <div className='account-side-menu-card' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#f0f4f8' }}>
        {isMobcreen &&
          <span style={{ color: '#5e6e82', padding: '5px 10px 5px 10px' }} className={`syncIcon`} onClick={() => toggleCollapsed({ toggleCollapsed })}>
            <IconButton style={{ padding: collapsed && isMobcreen && 0 }}>
              {React.createElement(collapsed ? MenuIcon : CloseOutlinedIcon)}
            </IconButton>
          </span>
        }
      </div>
      <Menus onClick={handleClick} mode="vertical" inlineCollapsed={collapsed} className="sideMenuN">
        <Button
          component={Link}
          to={{ pathname: "/account/overview", state: { from: "root" } }}
          className={`menuColor ${setNevActive('account/overview') && `thiActive`}`}
        >
          <span><AccountBoxOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>
              Account Overview
            </span>
          }
        </Button>
        <Button
          component={Link}
          to={{ pathname: "/account/settings", state: { from: "root" } }}
          className={`menuColor ${setNevActive('account/setting') && `thiActive`}`}
        >
          <span><ListAltOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>
              My Details
            </span>
          }
        </Button>
        <Button
          component={Link}
          to={{ pathname: "/account/orders", state: { from: "root" } }}
          className={`menuColor ${setNevActive('account/order') && `thiActive`}`}
        >
          <span><LocalMallOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>My Orders</span>
          }
        </Button>
        {/* <Button
          component={Link}
          to={{ pathname: "/account/payment-methods", state: { from: "root" } }}
          className={`menuColor ${setNevActive('payment-methods') && `thiActive`}`}
        >
          <span><AddCardOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>Payment Methods</span>
          }
        </Button> */}
        <Button
          component={Link}
          to={{ pathname: "/account/account-wallet", state: { from: "root" } }}
          className={`menuColor ${setNevActive('account/wallet') && `thiActive`}`}
        >
          <span><AccountBalanceWalletOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>My Wallet</span>
          }
        </Button>
        <Button
          component={Link}
          to={{ pathname: "/account/support", state: { from: "root" } }}
          className={`menuColor ${setNevActive('support') && `thiActive`}`}
        >
          <span><QuestionMarkOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>Support</span>
          }
        </Button>
        <Button
          component={Link}
          to={{ pathname: "/account/shipping-addresses", state: { from: "root" } }}
          className={`menuColor ${setNevActive('account/shipping-addresses') && `thiActive`}`}
        >
          <span><PushPinOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>Shipping Addresses</span>
          }
        </Button>
        <Button
          onClick={(() => userLogout())}
          className={`menuColor`}
        >
          <span><ExitToAppOutlinedIcon className="sideMenuIcon" /></span>
          {!collapsed &&
            <span>Logout</span>
          }
        </Button>
      </Menus>
    </div>
  )
}
export default SideMenu;