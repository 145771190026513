import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import Host from '../../Components/Host';
import { useGetUserDataQuery } from '../../services/login';
import HelmetComponent from '../../Components/HelmetComponent';
function ShippingPolicy() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const [getSearchId, setSearchId] = useState("")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })


  const myElementRef = React.useRef(null);
  const myElementRefs = React.useRef(null);
  const myContact = React.useRef(null);

  const myPayment = React.useRef(null);
  const myBilling = React.useRef(null);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash === 'returns') {
        myElementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'shipping') {
        myElementRefs.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'contact') {
        myContact.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'billing') {
        myBilling.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (hash === 'payment') {
        myPayment.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [window, window.location.hash]);

  return (
    <>
      <HelmetComponent title={'Shipping Policy'} />
      <div className="statisc-page">
        <div className="container static-container">
          <div className="row static-row">
            <div className="col-md-10">
              <div className="static-container-card"
                style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
                <h1 style={{ padding: '20px 0px 20px 0px', fontWeight: 300, fontSize: '1.4rem' }}>
                  {t('Shipping Policy')}
                </h1>


                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  This policy applies to domestic {userData?.contant?.country} orders. This policy is designed to ensure that you are aware of our shipping policies and procedures. By ordering from this store you accept the policies contained herein.
                </p>

                <h4>A. SHIPPING COSTS</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  All shipping costs are included in the item price. A flat delivery fee {userData?.contant?.currency} {userData?.contant?.standard_delivery} per order is charged on the cart.
                </p>

                <h4>B. TRANSIT, HANDLING, AND DELIVERY</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  Order processing time is 1-2 working days (Monday to Friday).
                </p>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  Shipments are generally in transit for 2-8 working days (Monday to Friday).
                </p>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  The order deadline will be 4PM Gulf Standard Time (Dubai).
                </p>



                <h4>D. TRANSPORT COURIER</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  We deliver the Products to customers through renowned carriers DHL, FedEx, UPS, FlipXp.
                </p>


                <h4>E. CHANGE OF ADDRESS</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  We cannot change the delivery address once it is in transit. If you need to change the place for the delivery of the order, please contact us within 24 hours of placing the order at {Host?.email}.
                </p>


                <h4>F. CANCELLATION</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  If you change your mind before you receive your order, we can accept cancellations at any time before your order is shipped. If an order has already shipped, please see our refund policy.
                </p>

                <h4>G. PARCELS DAMAGED IN TRANSIT</h4>
                <p style={{ marginBottom: '11px', fontSize: '11pt' }}>
                  If you find that a package is damaged in transit, if possible, please reject the package from the courier and contact our customer service. If the package was delivered without you being present, please contact customer service with the following steps.
                </p>


                <p>&nbsp;</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingPolicy;

