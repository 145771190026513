import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useApplePayCheckoutMutation } from '../services/payments';
import { addCheckoutMethod, addGuestShippment, addGuestPayment } from "../Reducers/authReducer";
import { useSelector, useDispatch } from "react-redux";
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const ApplePayCheckout = ({ setLoadingAuth, pErrorMessage, cartInfo, guestShippment, isGuest, userData, data }) => {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestPayment } = useSelector((state) => state.auth);
  const stripe = useStripe();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [isApplePayOpen, setIsApplePayOpen] = useState(false);
  const [getApplePayOpen, setApplePayOpen] = useState(false);
  const [createapplepay, applepayresult] = useApplePayCheckoutMutation()
  const ThreeDecimal = ['BHD', 'JOD', 'KWD', 'OMR', 'TND']
  const ZeroDecimal = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF']

  const cartData = cartInfo?.cart
  const thisToken = cartInfo?.token
  const couponData = cartInfo?.coupon

  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);


  function fetchCurrency(amount, currency) {
    if (ThreeDecimal?.includes(currency)) {
      return Number(amount) * 1000
    } else if (ZeroDecimal?.includes(currency)) {
      return Number(amount)
    } else {
      return Number(amount) * 100
    }
  }

  const [getResultStatus, setResultStatus] = useState(true);
  useEffect(() => {
    if (applepayresult?.data?.client_secret) {
      setResultStatus(false)
      setLoadingAuth(false)
    } else if (applepayresult?.data?.success) {
      setResultStatus(false)
      setLoadingAuth(false)
    } else {
      setResultStatus(true)
    }

    if (applepayresult?.isLoading) {
      setLoadingAuth(true)
    }

  }, [applepayresult, applepayresult?.data?.success, applepayresult?.data?.client_secret, setLoadingAuth])


  useEffect(() => {
    try {
      if (applepayresult?.data?.success) {
        dispatch(addCheckoutMethod({ isPurchase: cartToken }))
        dispatch(addGuestShippment({ guestShippment: '' }))
        dispatch(addGuestPayment({ guestPayment: '' }))
        let ItemsData = []
        applepayresult?.data?.items?.map((item) => {
          ItemsData.push({
            item_id: item?.product_id,
            item_name: item?.title,
            price: item?.price,
            quantity: item?.quantity
          })
        })
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: applepayresult?.data?.order?.id,
            currency: applepayresult?.data?.order?.currency,
            value: Number(applepayresult?.data?.order?.value),
            shipping: Number(applepayresult?.data?.order?.shipping),
            items: ItemsData
          }
        });


        let ItemsDataSuccess = []
        applepayresult?.data?.items?.map((item) => {
          ItemsDataSuccess.push({
            product_id: item?.product_id,
            title: item?.title,
            conditions: item?.conditions,
            current_variant: item?.current_variant,
            estimated_delivery: item?.estimated_delivery,
            quantity: item?.quantity,
            image: item?.image
          })
        })
        window.PurchaseItems = [];
        window.PurchaseItems.push({
          order_number: applepayresult?.data?.order_number,
          status: applepayresult?.data?.status,
          items: ItemsDataSuccess
        });


        history.push(`/purchase/success?order=${applepayresult?.data?.order_number}&token=${applepayresult?.data?.token}`)
      } else {
        setLoadingAuth(false);
      }
    } catch (e) {
      setLoadingAuth(false);
    }
  }, [applepayresult, applepayresult?.data?.success, setLoadingAuth])


  useEffect(() => {
    try {
      if (stripe && getResultStatus) {
        const thisAmount = couponData ? Number(TotalPrice) - Number(couponData?.discount) : Number(TotalPrice)
        const thisPaymentRequest = stripe.paymentRequest({
          country: countryCode,
          currency: cartData?.[0]?.prices?.currency?.toLowerCase(),
          total: {
            label: 'Apple pay checkout',
            amount: userEmail === 'anas@ebtida.com' ? 200 : fetchCurrency(Number(thisAmount) + Number(userData?.contant?.standard_delivery), cartData?.[0]?.prices?.currency),
          },
          requestPayerName: true,
          requestPayerEmail: true,
          requestShipping: true,
          shippingOptions: [
            {
              id: 'standard',
              label: 'Standard Shipping',
              amount: userData?.contant?.standard_delivery * 100,
            },
          ],
        });
        thisPaymentRequest.canMakePayment().then(result => {
          if (result && result.applePay) {
            setPaymentRequest(thisPaymentRequest);
            setCanMakePayment(true);
          }
        });

        thisPaymentRequest.on('merchantvalidation', () => {
          setIsApplePayOpen(true);
        });

        thisPaymentRequest.on('paymentauthorized', () => {
          setIsApplePayOpen(false);
        });

        thisPaymentRequest.on('cancel', () => {
          setIsApplePayOpen(false);
        });


        thisPaymentRequest.on('paymentmethod', async (event) => {
          const amount = couponData ? Number(TotalPrice) - Number(couponData?.discount) : Number(TotalPrice)
          const shipping = Number(userData?.contant?.standard_delivery)
          const currency = cartData?.[0]?.prices?.currency
          const shippAddress = {
            address: event?.shippingAddress?.addressLine?.toString(),
            city: event?.shippingAddress?.city,
            country: event?.shippingAddress?.country,
            country_code: event?.shippingAddress?.country,
            name: event?.payerName,
            email: event?.payerEmail,
            phone: event?.shippingAddress?.phone,
            street: event?.shippingAddress?.addressLine?.toString(),
            zip_code: ""
          }
          const getShipAddress = guestShippment?.email ? guestShippment : shippAddress
          const response = await fetch(`${process.env.REACT_APP_API_CONNECT}/api/checkout/apple/pay`, {
            method: 'POST',
            headers: {
              'X-Country-Code': countryCode,
              'X-User-Token': userToken,
              'X-User-Id': userId,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              amount: amount,
              shipping: shipping,
              currency: currency,
              token: thisToken,
              userEmail: userEmail ? userEmail : event?.payerEmail ? event?.payerEmail : guestShippment?.email,
              apple_pay: 'apple_pay',
              shipping_address: getShipAddress,
              shipping_address_id: data?.shipping_address_id,
              user_id: userId ? userId : null,
              language: languageCode,
              country: countryCode,
              amount: amount,
              shipping: shipping,
              currency: currency,
              delivery_days: Number(userData?.contant?.delivery_days),
              name: event?.payerName || guestShippment?.name,
              email: userEmail ? userEmail : event?.payerEmail ? event?.payerEmail : guestShippment?.email,
              products: cartData,
              cartToken: cartToken,
              domainName: data?.domainName,
              domain: data?.domain,
              domainEmail: data?.domainEmail,
              userEmail: userEmail ? userEmail : event?.payerEmail ? event?.payerEmail : guestShippment?.email,
              countryCode: countryCode,
              token: thisToken,
              coupon: couponData ? couponData : null,
            }),
          });
          const { clientSecret, order_number, order_id } = await response.json();
          if (clientSecret) {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              clientSecret,
              { payment_method: event?.paymentMethod?.id },
              { handleActions: false }
            );

            if (error) {
              event.complete('fail');
              setLoadingAuth(false)
              pErrorMessage('Payment rejected. Please use a different card.')
            } else {
              event.complete('success');
              if (paymentIntent.status === 'succeeded') {
                createapplepay({
                  payment_method_id: paymentIntent?.id,
                  shipping_address: getShipAddress,
                  shipping_address_id: data?.shipping_address_id,
                  user_id: userId ? userId : null,
                  language: languageCode,
                  country: countryCode,
                  amount: amount,
                  shipping: shipping,
                  currency: currency,
                  delivery_days: Number(userData?.contant?.delivery_days),
                  name: event?.payerName || guestShippment?.name,
                  email: userEmail ? userEmail : event?.payerEmail ? event?.payerEmail : guestShippment?.email,
                  products: cartData,
                  cartToken: cartToken,
                  domainName: data?.domainName,
                  domain: data?.domain,
                  domainEmail: data?.domainEmail,
                  userEmail: userEmail ? userEmail : event?.payerEmail ? event?.payerEmail : guestShippment?.email,
                  countryCode: countryCode,
                  token: thisToken,
                  coupon: couponData ? couponData : null,
                  apple_pay_success: 'apple_pay_success',
                  order_number: order_number,
                  order_id: order_id
                })
              } else {
                setLoadingAuth(false)
                pErrorMessage('Payment rejected. Please use a different card.')
              }
              // if (paymentIntent.status === 'requires_action') {
              //   stripe.confirmCardPayment(clientSecret);
              // }
            }
          } else {
            setLoadingAuth(false)
            pErrorMessage('Payment rejected. Please use a different card.')
          }
        });
      }
    } catch (error) {
    }
  }, [stripe, couponData, userData, cartData, cartInfo, guestShippment, data, data?.shipping_address_id, setLoadingAuth]);



  const handleClick = () => {
    if (paymentRequest) {
      if (cartData?.length > 0) {
        if (isGuest) {
          if (guestShippment?.email) {
            paymentRequest.show();
            setIsApplePayOpen(true);
          }
        } else {
          paymentRequest.show();
          setIsApplePayOpen(true);
        }

      }
    }
  };


  function getGuestShippoing(guest, email) {
    if (guest) {
      if (email) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {canMakePayment &&
        <Button
          disabled={getGuestShippoing(isGuest, guestShippment?.email)}
          variant='outlined'
          onClick={handleClick}
          style={{ padding: '10px', borderRadius: 8, fontSize: '16px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginBottom: '10px', justifyContent: 'space-between', cursor: 'pointer', fontWeight: 400, border: '1px solid #dddddd', color: '#212529', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <svg width="20" height="20" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16">
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
                <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
              </svg>
              <span style={{ width: '100%', whiteSpace: 'nowrap', margin: '0px 5px' }}>Apple Pay</span>
            </span>
            {getApplePayOpen ?
              <CircularProgress style={{ width: '1.3rem', height: '1.3rem', color: '#fe7f4a' }} /> :
              <div style={{ fontSize: '11px', fontWeight: '500', display: 'flex', alignItems: 'center' }}>
                <span>Continue with Apple</span>
                <ArrowForwardIcon style={{ fontSize: '1rem', margin: '0px 5px' }} />
              </div>
            }
          </div>

          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <PaymentRequestButtonElement
              className="hidden-payment-request-button"
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    theme: 'light-outline',
                    height: '40px'
                  },
                },
              }}
            // options={{ paymentRequest }}
            />
          </div>
        </Button>
      }
    </div>
  );
};


export default ApplePayCheckout;