import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useGetUserDataQuery } from '../../services/login';
import { useGetOrderQuery } from '../../services/membership';
import { useSelector } from "react-redux";
import HelmetComponent from '../../Components/HelmetComponent';
import SideMenu from './SideMenu';
import Loader from '../../Components/Loader';
import Host from '../../Components/Host';
import OrdersList from './Membership/OrdersList';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/joy/Button';
var moment = require('moment');

function AccountOverview() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode, countryCode } = useSelector((state) => state.auth);

  const history = useHistory();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15


  const membership = useGetOrderQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    domain: Host?.domain,
    countryCode: countryCode
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const membershipData = membership?.data?.order_items


  const userData = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const getUserData = userData?.data?.user;



  return (
    <>
      <HelmetComponent title={'Settings'} />
      <div className="Account-page tabel_cards">

        <div className="container">
          <div className='account-section'>
            <SideMenu />
            <div className='op-section' style={{ width: '100%' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-md-6">

                  {userData?.isLoading ?
                    <div className='op-card' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '180px' }}><Loader /></div>
                    :
                    <div className='op-card' style={{ position: 'relative', height: '220px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '1.2rem', fontWeight: 500, padding: '10px 0px 10px 0px' }}>Your Details</div>
                        <Button component={Link} to={`/account/settings`} variant='outlined' size='sm' style={{ fontWeight: 400 }}>View</Button>
                      </div>
                      <div className="row-card">
                        <div style={{ fontSize: '1.2rem' }}><span style={{ fontWeight: 300 }}>{getUserData?.name}</span></div>
                      </div>
                      <div className="row-card">
                        <div style={{ fontSize: '1.2rem' }}><span style={{ fontWeight: 300 }}>{getUserData?.email}</span></div>
                      </div>
                      <div className="row-card">
                        <div style={{ fontSize: '1.2rem' }}><span style={{ fontWeight: 300 }}>{getUserData?.phone ? getUserData?.phone : ''}</span></div>
                      </div>
                    </div>
                  }
                </div>
                <div className="col-md-6">
                  <div className='op-card' style={{ position: 'relative', height: '220px', textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ fontSize: '1.2rem', fontWeight: 500, padding: '10px 0px 10px 0px' }}>Account Wallet</div>
                      <Button component={Link} to={`/account/account-wallet`} variant='outlined' size='sm' style={{ fontWeight: 400 }}>View</Button>
                    </div>
                    <div style={{ fontWeight: 600, marginBottom: '10px', fontWeight: 300, fontSize: '1.2rem' }}>Available Balance</div>
                    <div style={{ fontSize: '2rem' }}>{userData?.data?.contant?.currency} 0</div>
                  </div>
                </div>
                <div className="col-md-12">
                  {membershipData?.length > 0 &&
                    <div style={{ fontSize: '1.2rem', fontWeight: 500, padding: '10px 0px 10px 0px' }}>Recent Orders</div>
                  }
                  {membership?.isLoading ?
                    <div className='op-card' style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}><Loader /></div>
                    :
                    <>
                      {membershipData?.map((item, i) =>
                        <div className='op-card' key={i}>
                          <div className='op-card-header'>
                            <OrdersList orders={item} />
                            <div className='op-o-info'>
                              <div style={{ marginBottom: '6px' }}><span style={{ fontWeight: 500 }}>{t('order_number')}: &nbsp;</span>{item?.order_number}</div>
                              <div style={{ marginBottom: '6px' }}><span style={{ fontWeight: 500 }}>{t('total_items')}:&nbsp;</span>{item?.order_items?.length}</div>
                              <div style={{ marginBottom: '6px' }}>
                                <span style={{ fontWeight: 500 }}>{t('order_status')}: &nbsp;</span>
                                {item?.states === 'cancelled' ? <span className='badge badge-danger'>Cancelled</span> :
                                  <>
                                    {item?.status ?
                                      <span className='badge badge-success'>Confirmed</span> :
                                      <span className='badge badge-warning'>Payment pending</span>
                                    }
                                  </>
                                }
                              </div>
                              <div style={{ marginBottom: '6px' }}>
                                <span style={{ fontWeight: 500 }}>{t('order_placed_on')}: &nbsp;</span>{moment(item?.createdAt).format("MMM Do YYYY")}
                              </div>
                              <div style={{ marginBottom: '6px' }}>
                                <Button
                                  style={{ fontSize: '14px', fontWeight: 500 }}
                                  component={Link}
                                  variant='outlined'
                                  color='warning'
                                  size='sm'
                                  to={`/account/order/${item?.order_number}`} >
                                  {t('Track')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {membershipData?.length === 0 &&
                        <div className="col-md-12" style={{ textAlign: 'center' }}>
                          <div className="no-data-found"> {t('No Recent Orders Found')}</div>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountOverview;

