import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Button from '@mui/joy/Button';
import { useTranslation } from 'react-i18next'

function Variation({ productData, productVarData, isLoading, isFetching, id, variant, isVarLoading, isVarFetching }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  function variantsFetching() {
    if (isFetching) {
      return true
    } else if (isVarFetching) {
      return true
    } else {
      return false
    }
  }

  function variantsLoading() {
    if (isLoading) {
      return true
    } else if (isVarLoading) {
      return true
    } else {
      return false
    }
  }

  const vAtrributes = productData?.variants?.variation_attributes || productVarData?.variants?.variation_attributes
  const cVariant = productData?.current_variant || productVarData?.current_variant
  const vVariations = productData?.variants?.variations || productVarData?.variants?.variations

  function isVariantsExist() {
    if (productData?.variants) {
      return true
    } else if (productVarData?.variants) {
      return true
    } else {
      return false
    }
  }


  const [getVariantA, setVariantA] = useState('');

  function checkUnique(data) {
    const uniqueVariations = {};
    let uniqueObjects = [];
    data?.forEach(item => {
      if (!uniqueVariations[item.value]) {
        uniqueVariations[item.value] = true;
        uniqueObjects.push(item);
      }
    });
    return uniqueObjects
  }

  function getAllValeusaaa(variations, attributes, number, number2, value) {
    let thsiData = [];
    Object.entries(variations || [])?.forEach(([productId, productDetails]) => {
      if (value) {
        if (productDetails[attributes?.[number2]] === value) {
          if (productDetails[attributes?.[number]]) {
            thsiData.push({
              asin: productId,
              value: productDetails[attributes?.[number]],
            })
          } else { return null }
        } else { return null }
      } else {
        if (productDetails[attributes?.[number]]) {
          thsiData.push({
            asin: productId,
            value: productDetails[attributes?.[number]],
          })
        } else { return null }
      }
    });
    const sortedArray = [...thsiData]?.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });

    return sortedArray
  }




  const variantOne = getAllValeusaaa(vVariations, vAtrributes, 0, 0, null)
  const uniqueValues1 = checkUnique(variantOne || null)
  const varUniOne = uniqueValues1?.length > 0 && {
    name: vAtrributes?.[0],
    current: cVariant?.[vAtrributes?.[0]],
    data: uniqueValues1 || []
  }
  const getVariantUtl11 = variantOne?.find((item) => item.asin === variant)

  const variantTwo = getAllValeusaaa(vVariations, vAtrributes, 1, 0, getVariantUtl11?.value)
  const uniqueValues2 = checkUnique(variantTwo || null)
  const varUniTwo = uniqueValues2?.length > 0 && {
    name: vAtrributes?.[1],
    current: cVariant?.[vAtrributes?.[1]],
    data: uniqueValues2 || []
  }
  const getVariantUtl22 = variantTwo?.find((item) => item.asin === variant)

  const variantThree = getAllValeusaaa(vVariations, vAtrributes, 2, 1, getVariantUtl22?.value)
  const uniqueValues3 = checkUnique(variantThree || null)
  const varUniThree = uniqueValues3?.length > 0 && {
    name: vAtrributes?.[2],
    current: cVariant?.[vAtrributes?.[2]],
    data: uniqueValues3 || []
  }
  const getVariantUtl33 = variantThree?.find((item) => item.asin === variant)

  const variantFour = getAllValeusaaa(vVariations, vAtrributes, 3, 2, getVariantUtl33?.value)
  const uniqueValues4 = checkUnique(variantFour || null)
  const varUniFour = uniqueValues4?.length > 0 && {
    name: vAtrributes?.[3],
    current: cVariant?.[vAtrributes?.[3]],
    data: uniqueValues4 || []
  }

  const thisArrayMerged = [
    ...(varUniOne ? [{ name: varUniOne?.name || '', current: varUniOne?.current || '', data: varUniOne?.data || [] }] : []),
    ...(varUniTwo ? [{ name: varUniTwo?.name || '', current: varUniTwo?.current || '', data: varUniTwo?.data || [] }] : []),
    ...(varUniThree ? [{ name: varUniThree?.name || '', current: varUniThree?.current || '', data: varUniThree?.data || [] }] : []),
    ...(varUniFour ? [{ name: varUniFour?.name || '', current: varUniFour?.current || '', data: varUniFour?.data || [] }] : []),
  ]


  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }

  return (
    <>
      {variantsLoading() ?
        <div style={{width: '60%'}}>
          <Skeleton count={2} inline={true} variant="rect" style={{ height: '35px', width: '45%', margin: '0px 3px' }} />
        </div>
        :
        <>
          {isVariantsExist() &&
            <>
              <>
                {thisArrayMerged?.map((item, index) =>
                  <>
                    {item?.data?.length ?
                      <div key={index} style={{ filter: variantsFetching() && 'opacity(0.5)', padding: '5px 0px 5px 0px' }}>
                        <div style={{ padding: '0px 0px 3px 0px', fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
                          <span className='product-brand' style={{ fontWeight: 500, padding: 0 }}>{item?.name?.replace(':', '')}:</span>
                          <span style={{ margin: '0px 5px', color: '#6d6d6d' }}>{item?.current}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                          {item?.data?.slice(0, getVariantA === item.name ? 100 : 5)?.map((itemc, i) =>

                            itemc.value &&
                            <Button
                              key={i}
                              disabled={item.current === itemc.value || variantsFetching()}
                              style={{
                                width: 'auto',
                                minHeight: '30px',
                                borderRadius: '4px',
                                margin: '3px 3px',
                                textTransform: 'capitalize',
                                color: item.current === itemc.value ? '#4e93d9' : '#444',
                                fontWeight: item.current === itemc.value ? 400 : 300,
                                fontSize: '14px',
                                border: item.current === itemc.value ? '1px solid #97c3f0' : '1px solid #0000001f',
                                padding: '5px 15px'
                              }}
                              variant="outlined"
                              component={Link}
                              to={`/product/${productData?.store_id}/${id}?variant=${itemc.asin}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{itemc.value}</span>
                              </div>
                            </Button>

                          )}
                          {item?.data?.length > 5 &&
                            <div
                              variant="outlined"
                              onClick={(() => setVariantA(getVariantA === item.name ? '' : item.name))}
                              style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
                              <div>{getVariantA === item.name ? t('see_less') : `${t('see_all')} ${item?.data?.length}`}</div>
                              <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
                            </div>
                          }
                        </div>
                      </div> : null
                    }
                  </>
                )}
              </>
            </>
          }
        </>
      }
    </>
  );
}

export default Variation;
