import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import { useFetchQuery } from '../../services/cart';
import HelmetComponent from '../../Components/HelmetComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tag } from "antd";
var moment = require('moment');

function PurchaseSuccess() {
  const { userId, isPurchase, userToken, languageCode, cartToken, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { success } = useParams();
  const dispatch = useDispatch();
  const order_number = new URLSearchParams(location.search).get('order') || null
  const token = new URLSearchParams(location.search).get('token') || null

  const { refetch } = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (token && order_number) {
      refetch()
    }
    try {
      if (!isPurchase) {
        history.push(`/`)
      } else {
        if (isPurchase !== token) {
          history.push(`/`)
        }
      }

    } catch (e) {
    }
  }, [isPurchase, cartToken, token])


  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  function getDeliverydays(data, created) {
    function getTimeline() {
      if (Number(data) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(data) > 0) {
      return <><strong>{getTimeline()}</strong> {moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = window.PurchaseItems?.[0]?.items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const soteIds = [3, 1]
  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }
  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s.,]/gi, ' ')?.replace(/ /g, '-')
  }

  function getitesmData(data) {
    return (
      <div className='sp-section'>
        <div className='sp-card' style={{ background: '#fff0', padding: '20px' }}>
          <div className='sp-heading'>{t('your_order')}</div>
          <div className='sp-card-header'>
            <div style={{ fontSize: '14px' }}>
              <div>Order Number: &nbsp;{window.PurchaseItems?.[0]?.order_number}</div>
              <div>Total Items: &nbsp;{window.PurchaseItems?.[0]?.items?.length}</div>
            </div>
            <div style={{ fontSize: '14px' }}>
              <div>Order Status: &nbsp;{window.PurchaseItems?.[0]?.status ?
                <span style={{ color: 'green' }}>Placed</span> :
                <span style={{ color: 'tomato' }}>Pending</span>}</div>
              <div>{t('order_placed_on')} {moment(data?.createdAt).format("MMM Do YYYY")}</div>
            </div>
          </div>
          {/* {!window.PurchaseItems?.[0]?.status &&
            <Tag color='error' style={{ margin: '10px 0px' }}>Payment is pending</Tag>
          } */}
          {data.shipping_status === 'cancelled' &&
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Tag color='error' style={{ minHeight: '30px', height: '30px', display: 'flex', alignItems: 'center', textTransform: 'capitalize', fontSize: '1rem' }}>
                {data.shipping_status?.replace('_', ' ')}
              </Tag>
            </div>
          }
          {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
            <div
              key={group}
              style={{
                border: '1px solid #eee',
                borderRadius: '10px',
                padding: '15px',
                background: '#f0f4f8',
                marginBottom: '15px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                {groupItems?.[0].shipping_status?.split(',')?.includes('delivered') ?
                  <div style={{ padding: '10px 0px 10px 0px', display: 'flex', alignItems: 'center' }}>
                    <Tag style={{ height: '30px', minHeight: '30px', display: 'flex', alignItems: 'center' }}>
                      Delivered on:&nbsp;{moment(groupItems?.[0].delivered_on).format('Do MMM YYYY HH:mm')}
                    </Tag>
                  </div> :
                  <div style={{ fontSize: '14px', fontWeight: 500 }}>
                    Delivery by&nbsp;{getDeliverydays(group, data?.createdAt)}
                  </div>
                }
                <div style={{ fontSize: '14px', fontWeight: 500 }}>Items: {groupItems?.length}</div>
              </div>

              <div className='sp-item-section'>
                {groupItems?.map((items, index) =>
                  <div key={index}>
                    <div className='sp-item-card' style={{ background: '#fff', padding: '12px' }}>
                      <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                        <img className='sp-item-img' src={items.image} />
                      </Link>
                      <div className='sp-item-title'>
                        <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                          <p style={{ margin: 0 }}>{items.title?.replace(/[^\w\s.,/:']/gi, ' ')}</p>
                        </Link>
                        <div className='sp-item-content' style={{ padding: '5px 0px 0px 0px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', flexWrap: 'wrap' }}>
                            <Tag>Condition: {items.conditions}</Tag>
                            <Tag>Qty.: {items.quantity}</Tag>
                          </div>
                        </div>
                        {items.current_variant &&
                          <div style={{ padding: '5px 0px 0px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {Object.entries(items.current_variant || []).map(([key, value], index) => (
                              <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <HelmetComponent title={'Purchase success'} />
      <div className="Purchase-page-Success Account-page">
        <br /><br />
        <div className="container purchase-container">
          <div className="row purchase-section">

            <div className="col-md-6 mp0">
              <div className="row" style={{ justifyContent: 'center', width: '100%' }}>
                {isPurchase &&
                  <div className="col-md-12 mp0">
                    <div className='ps-message'>
                      <div><CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} /></div>
                      <div className='message'>
                        Your order <strong>#{order_number}</strong> has been placed successfully. <br />Thank you
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>

            {window.PurchaseItems?.[0]?.items &&
              <div className="col-md-6 mp0">
                <div className="row" style={{ justifyContent: 'center', width: '100%' }}>
                  <div className="col-md-12 mp0">
                    <>
                      {getitesmData(window.PurchaseItems?.[0]?.items)}
                    </>
                  </div>
                </div>
              </div>
            }
          </div>
        </div >
        <br /><br />
      </div >
    </>
  );
}

export default PurchaseSuccess;
