import React, { useState, useEffect, Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';



import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    "&::-webkit-scrollbar": {
      display: "none"
    }, /* Chrome */
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));



function ProductCarouselSkeleton() {
  const classes = useStyles();

  return (
    <ImageList className={classes.imageList} cols={6}>
      {Array(6).fill().map((_, index) => (
        <ImageListItem className="cardItem" key={index}>
          <div className="cardItem-item">
            <div className='img-card imgCard'>
              <Skeleton className='skeleton img-item' variant="rect" style={{ width: '90%', height: '90%' }} />
            </div>
            <div className="card-content">
              <div className='title-card'>
                <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '100%' }} />
                <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '90%', marginTop: '5px' }} />
              </div>
              <div className='content-item'>
                <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '50%' }} />
              </div>
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '30%' }} />
              </div>
            </div>
          </div>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

export default ProductCarouselSkeleton;
