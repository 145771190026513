import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Login'],
  refetchOnMountOrArgChange: 1,
  keepUnusedDataFor: 86400000,
  endpoints: (builder) => ({
    getUserData: builder.query({
      query: (data) => {
        return {
          url: `api/login/${data.userId ? data.userId : 'user'}`,
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-Country-Code': data.countryCode,
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Login'],
    }),
    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: `api/login`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.country,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),

    forgetPassword: builder.mutation({
      query: (data) => {
        return {
          url: `api/login/${data.email}`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.country,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),

    resetPassword: builder.mutation({
      query: (data) => {
        return {
          url: `api/login`,
          method: 'PUT',
          body: data,
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),

    updateUser: builder.mutation({
      query: (data) => {
        return {
          url: `api/membership/${data.userId}/${data.mid}`,
          method: 'PUT',
          body: data,
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),


    updateUserDetails: builder.mutation({
      query: (data) => {
        return {
          url: `api/login/${data.userId}`,
          method: 'PUT',
          body: data,
          headers: {
            'X-Country-Code': data.country,
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),


    cancelMembership: builder.mutation({
      query: (data) => {
        return {
          url: `api/membership/${data.userId}/${data.mid}`,
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),






    getShippingAddresses: builder.query({
      query: (data) => {
        return {
          url: `api/shipping_addresses/${data.countryCode}/${data.userId}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Login'],
    }),


    createShippingAddress: builder.mutation({
      query: (data) => {
        return {
          url: `api/shipping_addresses/${data.userId}`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),


    cancelAddress: builder.mutation({
      query: (data) => {
        return {
          url: `api/shipping_addresses/${data.userId}`,
          method: 'DELETE',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Login'],
    }),




  }),

})

export const { useLoginUserMutation, useForgetPasswordMutation, useGetUserDataQuery, useResetPasswordMutation, useUpdateUserMutation, useUpdateUserDetailsMutation, useCancelMembershipMutation, useGetShippingAddressesQuery, useCreateShippingAddressMutation, useCancelAddressMutation } = loginApi