import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const trackApi = createApi({
  reducerPath: 'trackApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Track'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    findOrder: builder.mutation({
      query: (data) => {
        return {
          url: `api/track/order`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Track'],
    }),

    verify: builder.mutation({
      query: (data) => {
        return {
          url: `api/track/order/${data.otp}`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Track'],
    }),

 

  }),

})

export const { useFindOrderMutation, useVerifyMutation } = trackApi