import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useMediaQuery } from 'react-responsive'

function GoogleAuth({ googleAuthLogin }) {
  const { languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  return (
    <>
      <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="form-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <GoogleOAuthProvider
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
          >
            <GoogleLogin
              shape='square'
              className="google-login-button"
              locale={languageCode === 'ar' ? 'ar' : 'en'}
              size="large"
              type="standard"
              text="continue_with"
              width={!isMobcreen ? '400px' : '250px'}
              onSuccess={response => {
                googleAuthLogin(response)
              }}
              onError={() => {
              }}
              useOneTap
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </>
  );
}

export default GoogleAuth;
