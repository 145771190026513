import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import {
  ApplePayButton,
  ThemeMode,
  SupportedNetworks,
  Scope,
  Environment,
  Locale,
  ButtonType,
  Edges
} from '@tap-payments/apple-pay-button'

function TapApplePay({ amount, name, email, countryCode, number, currency, checkoutWithTabby, tapPublicKey }) {

  return (
    <>
      <ApplePayButton
        publicKey={tapPublicKey}
        environment={Environment.Production}
        debug
        merchant={{
          domain: window.location.hostname,
          id: '41624891'
        }}
        transaction={{
          amount: amount,
          currency: currency
        }}
        scope={Scope.TapToken}
        acceptance={{
          supportedBrands: [SupportedNetworks.Visa, SupportedNetworks.MasterCard],
          supportedCardsWithAuthentications: ["3DS"]
        }}
        features={{
          supportsCouponCode: false,
        }}
        customer={{
          name: [
            {
              lang: Locale.EN,
              first: name
            }
          ],
          contact: {
            email: email,
            phone: {
              countryCode: countryCode,
              number: number
            }
          }
        }}
        interface={{
          locale: Locale.EN,
          theme: ThemeMode.DARK,
          type: ButtonType.BUY,
          edges: Edges.CURVED
        }}
        onError={(err) => console.error('apple-error', err)}
        onSuccess={async (token) => {
          checkoutWithTabby(token?.id, 'tap_apple')
        }}
      />
    </>
  );
}

export default TapApplePay;
