import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { useForgetPasswordMutation } from '../../services/login';
import Host from '../../Components/Host';
import Button from '@mui/material/Button';
import OtpInput from "react-otp-input";
import ReCAPTCHA from "react-google-recaptcha";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from '../../Components/Loader';
function ForgetPasswordForm({ signinModalOpen, otpData, isLoading }) {
  const { languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm();
  const { t } = useTranslation()
  const recaptchaRef = React.useRef();
  const [forgetPassword, fpResult] = useForgetPasswordMutation()

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const [isTokenModal, setTokenModal] = useState(false);
  const [getEmail, setEmail] = useState('');

  async function goforgetPassword(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    await forgetPassword({
      email: data.email,
      captcha_token: token,
      domain: Host?.domain,
      domainEmail: Host?.email,
      country: countryCode
    })
  }


  useEffect(() => {
    if (fpResult?.isSuccess) {
      setTokenModal(true)
      setEmail(fpResult?.data?.email)
    }
  }, [fpResult]);

  const [otp, setOtp] = useState('');


  async function loginWithOtp() {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    otpData({
      token: otp,
      email: getEmail,
      captcha_token: token,
      country: countryCode
    })
  }





  return (
    <>
      {isTokenModal ?
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="col-md-12">
            <div
              style={{
                textAlign: 'center',
                padding: '20px 10px 20px 10px',
                fontSize: '1rem',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                direction: 'ltr'
              }}>
              <Button onClick={(() => setTokenModal(false))}><ArrowBackIcon style={{ fontSize: '1rem' }} /></Button>
              <div style={{ padding: '10px 5px 10px 5px' }}> {t('enter_your_otp')} </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(loginWithOtp)}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
            />
            <div className="col-md-12">
              <div className="form-group" style={{ display: 'flex', justifyContent: 'center' }}>
                <OtpInput
                  inputStyle="inputStyle"
                  containerStyle="otp"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<div style={{ padding: '5px' }}></div>}
                  id="code"
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
            <div className="col-md-12 Header-signin-button">
              <div className="form-group">
                <Button
                  type='submit'
                  disabled={isLoading}
                  variant="info"
                  style={{ height: '45px', background: Host?.bgColor, color: Host?.buttonColor, width: '100%', textTransform: 'capitalize' }}
                >
                  <span style={fontFamily}>
                    {isLoading ? <Loader /> :
                      t('submit')
                    }
                  </span>
                </Button>

              </div>
            </div>
          </form>
        </div>
        :
        <form onSubmit={handleSubmit(goforgetPassword)}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="Header-singin-header" style={{ textAlign: 'center', padding: '20px 10px 20px 10px', fontSize: '1rem', fontWeight: 400 }}>
                <div style={{ padding: '10px 5px 10px 5px' }}> {t('enter_your_email')} </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                {/* <label>{t('email')} </label> */}
                <input
                  type="email"
                  className="form-control dlrdflInput"
                  id="email"
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register('email', { required: true })} />
              </div>
            </div>
            <div className="col-md-12 Header-signin-button">
              <div className="form-group">

                <Button
                  disabled={fpResult?.isLoading}
                  variant="info"
                  type="submit"
                  style={{ height: '45px', background: Host?.bgColor, color: Host?.buttonColor, width: '100%', textTransform: 'capitalize' }}
                >
                  <span style={fontFamily}>
                    {fpResult?.isLoading ? <Loader /> :
                      t('continue')
                    }
                  </span>
                </Button>

              </div>
            </div>

          </div>
        </form>
      }
      <div className="col-md-12 Header-signin-button">
        <div className="form-group" style={{ textAlign: 'center' }}>
          <Button
            style={{ fontSize: '12px', textTransform: 'capitalize' }}
            onClick={(() => signinModalOpen(true))}>
            <span style={fontFamily}>{t('Login using different methods')}</span>
          </Button>
        </div>
      </div>
    </>
  );
}

export default ForgetPasswordForm;
