import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
import Host from '../../Components/Host';
import { useGetUserDataQuery } from '../../services/login';
import { useSelector } from "react-redux";
import HelmetComponent from '../../Components/HelmetComponent';
function FAQS() {
  const { userId, userToken, countryCode } = useSelector((state) => state.auth);
  const { t } = useTranslation()
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <>
      <HelmetComponent title={`FAQ's`} />
      <div className="statisc-page">

        <div className="container static-container">
          <div className="row static-row">
            <div className="col-md-10">
              <div className="static-container-card"
                style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
                <h1 style={{ padding: '20px 0px 20px 0px', fontWeight: 300, fontSize: '1.4rem' }}>
                  FAQ's
                </h1>
                <h4 style={{ fontWeight: 500 }}>Where do you ship?</h4>
                <p>We currently ship to 13 countries and if you can see this page then we ship to your country.</p>

                <h4 style={{ fontWeight: 500 }}>How long does it typically take for your products to be dispatched?</h4>
                <p>Usually within 48 hours of processing your order.</p>

                <h4 style={{ fontWeight: 500 }}>How long does it typically take for my purchase to arrive once it’s dispatched?</h4>
                <p>Usually within 3-10 business days, however, some items that require special logistic solutions can take longer.</p>

                <h4 style={{ fontWeight: 500 }}>Are all the products listed on your website In Stock?</h4>
                <p>Yes, all Products that you are able to add to cart are in stock. </p>

                <h4 style={{ fontWeight: 500 }}>Do I need to open an account to shop on your Website?</h4>
                <p>No, you don’t need to open an account to place an order on our Website. Simply checkout as a guest and provide the necessary details for us to process and deliver your order.</p>

                <h4 style={{ fontWeight: 500 }}>Is it safe to use my banking and card details on your Website?</h4>
                <p>Yes, shopping on our Website is completely safe. We use leading payment gateway providers that are Verisign Secured and PCI Compliant. At no point during the payment process do we handle your banking and card details, or store your banking and card details after the order is placed.</p>

                <h4 style={{ fontWeight: 500 }}>I’m unable to complete my order on the Website. What should I do?</h4>
                <p>Please contact customer care at {Host?.phone} or {Host?.email}. We’ll be happy to assist you.</p>

                <h4 style={{ fontWeight: 500 }}>Are my personal details safe on the Website?</h4>
                <p>Yes, we take utmost care in keeping your personal information safe and secure. We do not sell your information to any third-parties. For more details, refer to our Privacy Policy.</p>

                <h4 style={{ fontWeight: 500 }}>How do I track my order?</h4>
                <p>Once your order has been shipped we will email you a tracking number and a link to the courier’s website. Please contact our customer service team if you do not receive this email.</p>

                <h4 style={{ fontWeight: 500 }}>How much does the delivery cost?</h4>
                <p>We have a flat delivery fee of {userData?.contant?.currency} {userData?.contant?.standard_delivery} on the cart and any international shipping charges are included in the item price.</p>

                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQS;

