import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Subscription'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    getSubscriptions: builder.query({
      query: (data) => {
        return {
          url: `api/subscriptions`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Subscription'],
    }),


  }),

})

export const { useGetSubscriptionsQuery } = subscriptionApi