const Domains  = [
  {
    domain: ['ae.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['ae-en.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['auh.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['auh-en.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['dxb.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['dxb-en.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['en-ae.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['en-auh.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['en-dxb.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['en-uae.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['uae.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['uae-en.thwifty.com'],
    code: 'AE'
  },
  {
    domain: ['en-ksa.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['en-sa.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['en-sar.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['en-saudi.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['ksa.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['ksa-en.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['sa.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['sa-en.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['sar.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['sar-en.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['saudi.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['saudi-en.thwifty.com'],
    code: 'SA'
  },
  {
    domain: ['canada.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['can.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['ca.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['en-canada.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['en-can.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['en-ca.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['canada-en.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['can-en.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['ca-en.thwifty.com'],
    code: 'CA'
  },
  {
    domain: ['kuwait.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kw.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kwd.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kwt.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['en-kuwait.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['en-kw.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['en-kwd.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['en-kwt.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kuwait-en.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kw-en.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kwd-en.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['kwt-en.thwifty.com'],
    code: 'KW'
  },
  {
    domain: ['oman.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['muscat.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['om.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['oma.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['omn.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['omr.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['en-oman.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['en-muscat.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['en-om.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['en-oma.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['en-omn.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['en-omr.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['oman-en.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['muscat-en.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['om-en.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['oma-en.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['omn-en.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['omr-en.thwifty.com'],
    code: 'OM'
  },
  {
    domain: ['uk.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['gb.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['gbr.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['gbp.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['eng.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['unitedkingdom.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['britain.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['british.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['england.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-uk.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-gb.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-gbr.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-gbp.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-eng.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-unitedkingdom.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-britain.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-british.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['en-england.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['uk-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['gb-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['gbr-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['gbp-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['eng-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['unitedkingdom-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['britain-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['british-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['england-en.thwifty.com'],
    code: 'GB'
  },
  {
    domain: ['bahrain.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['bh.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['bhd.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['en-bahrain.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['en-bh.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['en-bhd.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['bahrain-en.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['bh-en.thwifty.com'],
    code: 'BH'
  },
  {
    domain: ['bhd-en.thwifty.com'],
    code: 'BH'
  }
];

export default Domains;