function formatPrice(price, currency, shipping) {
  const numericPrice = Number(price) + Number(shipping);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ? currency : 'AED',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })?.format(numericPrice);
};

export default formatPrice;