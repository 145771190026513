import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next'
import Host from '../../Components/Host';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import HelmetComponent from '../../Components/HelmetComponent';

function AboutUs() {

  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <>
      <HelmetComponent title={'About Us'} />
      <div className="statisc-page">

        <div className="container static-container">
          <div className="row static-row">
            <div className="col-md-10">
              <div className="static-container-card"
                style={{ textAlign: 'left', padding: '20px', background: '#fcfcfc', border: '1px solid #eee', borderRadius: '7px' }}>
                <h1 style={{ padding: '20px 0px 20px 0px', fontWeight: 300, fontSize: '1.4rem' }}>
                  {t('about_us')}
                </h1>
                <p>Welcome to {Host?.domainName}! We are an e-commerce store based in Dubai, run by a passionate team of people working very hard to make sure we can offer you the best shopping experience possible. From electronics to fashion and beauty essentials… </p>
                <p>We offer secure payment options and reliable delivery services to ensure that your shopping experience is not only incredible but also efficient.</p>
                <p>At {Host?.domainName}, we take great pride in breaking the mold, so instead of doing things the easy way and using chatbots, our customer service team is made up entirely of real human beings.</p>
                <p>So if you’ve got questions, get in touch with our team of customer service professionals for a concierge-like experience seven days a week, between the hours of 8AM to 10PM UAE Time. Chat with us or call us at {Host?.phone}.</p>
                <p>At {Host?.domainName}, our mission is simple: providing the products you are looking for at the best prices, delivered to you as swiftly as possible, and making sure you come out at the other end of your shopping experience happy and satisfied. Happy shopping!</p>
                <p style={{ lineHeight: 2 }}>
                  <div dangerouslySetInnerHTML={{
                    __html: Host?.address
                  }}>
                  </div>
                  <MailOutlineIcon style={{ fontSize: '18px' }} /> {Host?.email} <br />
                  <PhoneIcon style={{ fontSize: '18px' }} /> {Host?.phone}<br />
                </p>
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;

