import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';

export default function Loader() {
  return (
    <div className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
 