import { useState } from 'react';

function useInquiryModal() {
  const [isInquiryModal, setInquiryModal] = useState(false);
  function inquiryModal() {
    setInquiryModal(isInquiryModal ? false : true);
  }
  return {
    isInquiryModal,
    inquiryModal,
  }
};

function useSigninModal() {
  const [isSigninModal, setSigninModal] = useState(false);
  function signinModal() {
    setSigninModal(isSigninModal ? false : true);
  }
  return {
    isSigninModal,
    signinModal,
  }
};

function useFPModal() {
  const [isFPModal, setFPModal] = useState(false);
  function FPModal() {
    setFPModal(isFPModal ? false : true);
  }
  return {
    isFPModal,
    FPModal,
  }
};


function useCartModal() {
  const [isCartModal, setCartModal] = useState(false);
  function cartModal() {
    setCartModal(isCartModal ? false : true);
  }
  return {
    isCartModal,
    cartModal,
  }
};


export { useInquiryModal, useSigninModal, useFPModal, useCartModal }