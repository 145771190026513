import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Host from '../../../Components/Host';
import HelmetComponent from '../../../Components/HelmetComponent';
import SideMenu from '../SideMenu';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

function Support() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  return (
    <>
      <HelmetComponent title="Support" />
      <div className="Account-page">
        <div className="container">
          <div className='account-section'>
            <SideMenu />
            <div className='op-section' style={{ width: '100%' }}>
              <div className="row" style={{ width: '100%', justifyContent: 'center' }}>
                <div className="col-md-10">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <QuestionMarkOutlinedIcon style={{ fontSize: '1.5rem' }} />
                    <div className='ap-title' style={{ fontSize: '1.2rem', margin: '0px 10px' }}>
                      {t('support')}
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="change-email" style={{ textAlign: 'center' }}>
                    <div><strong>{t('email')}: </strong>&nbsp; {Host?.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;

