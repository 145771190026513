import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import GoogleAuth from '../../Components/GoogleAuth';
import { useLoginUserMutation } from '../../services/login';
import CloseIcon from '@mui/icons-material/Close';
const md5 = require('md5');
import { addToCart } from "../../Reducers/authReducer";
import AppleSignin from 'react-apple-signin-auth';
import AppleIcon from '@mui/icons-material/Apple';
import Host from '../../Components/Host';
import ReCAPTCHA from "react-google-recaptcha";
import ForgetPasswordForm from './ForgetPasswordForm';
import Loader from '../../Components/Loader';

function LoginForm({ signinModal }) {
  const { languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const [loginUser, loginresult] = useLoginUserMutation()

  async function loginUserForm(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    await loginUser({
      email: data.email,
      password: md5(data.password),
      login: 'login',
      language: languageCode,
      country: countryCode,
      publicIp: null,
      captcha_token: token,
      domain: Host?.domain
    })
  }

  async function googleAuthLogin(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    await loginUser({
      token: data.credential,
      client_id: data.clientId,
      google_login: 'google_login',
      language: languageCode,
      country: countryCode,
      publicIp: null,
      domain: Host?.domain,
      captcha_token: token,
    })
  }



  useEffect(() => {

    try {

      if (loginresult?.data) {
        signinModal(false)
        dispatch(addToCart({
          userId: loginresult?.data?.id,
          userToken: loginresult?.data?.user_token,
          userEmail: loginresult?.data?.email,
          userName: loginresult?.data?.name,
          isMember: loginresult?.data?.member === 1 ? true : false,
          isPaymentMethod: loginresult?.data?.default_payment ? true : false,
          isCustomer: loginresult?.data?.customer ? true : false,
        }));
      }

      if (loginresult?.error) {
        setLoginError(true)
        setFPData(false)
        setFPError(false)
      }
    } catch (e) {
    }
  }, [loginresult]);



  const [getFPModal, setFPModal] = useState(false);
  function FPModalOpen() {
    setFPModal(true)
  }

  function signinModalOpen() {
    setFPModal(false)
  }

  async function otpData(data) {
    await loginUser({
      token: data.token,
      email: data.email,
      captcha_token: data.captcha_token,
      otp_login: 'otp_login',
      language: languageCode,
      country: countryCode,
      publicIp: null,
      domain: Host?.domain
    })
  }


  return (
    <>

      {getFPModal &&
        <ForgetPasswordForm signinModalOpen={signinModalOpen} otpData={otpData} isLoading={loginresult?.isLoading} />
      }
      {loginresult?.error?.data?.error &&
        <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px' }}>
          {loginresult?.error?.data?.error}
        </div>
      }

      {!getFPModal &&
        <>

          <form onSubmit={handleSubmit(loginUserForm)}>

            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
            />

            <div className="row">
              <div className="col-md-12">
                <div className="Header-singin-header" style={{ textAlign: 'center', padding: '20px 5px 0px 5px', fontSize: '1rem', fontWeight: 400 }}>
                  <div>{t('lets_get_started')} - <span>{t('sign_in')}</span> {t('or')} <span>{t('sign_up')}</span> </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('email')} </label>
                  <input
                    type="email"
                    className="form-control dlrdflInput"
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('password')} </label>
                  <input
                    type="password"
                    className="form-control dlrdflInput"
                    id="password"
                    aria-invalid={errors.password ? "true" : "false"}
                    {...register('password', { required: true, minLength: 8, maxLength: 16 })} />
                  {errors.password && errors.password.type === "required" && <span style={{ color: "red", fontSize: "12px" }}>required*</span>}
                  {errors.password && errors.password.type === "maxLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be maximum 16 digits</span>}
                  {errors.password && errors.password.type === "minLength" && <span style={{ color: "red", fontSize: "12px" }}>Password should be minimum 8 digits</span>}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Button
                    style={{ fontSize: '12px', textTransform: 'capitalize' }}
                    onClick={(() => FPModalOpen(true))}>
                    <span style={fontFamily}>{t('login_using_otp')}</span>
                  </Button>
                </div>
              </div>
              <div className="col-md-12 Header-signin-button">
                <div className="form-group">

                  <Button
                    disabled={loginresult.isLoading}
                    style={{ height: '45px', background: Host?.bgColor, color: Host?.buttonColor, width: '100%', textTransform: 'capitalize' }}
                    variant="info"
                    type="submit">
                    <span style={fontFamily}>
                      {loginresult.isLoading ? <Loader /> : t('submit')}
                    </span>
                  </Button>

                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="Login-card-separator-wrapper">
                    <div className="Login-card-separator-or" style={{ textAlign: 'center' }}>{t('or')}</div>
                  </div>
                </div>
              </div>
              <GoogleAuth googleAuthLogin={googleAuthLogin} />
            </div>
          </form>
        </>
      }

    </>
  );
}

export default LoginForm;
