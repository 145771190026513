import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import Countries from '../../Components/Header/Countries';
import './index.scss';
import { useSelector } from "react-redux";
import ActiveCountries from '../../Components/Header/ActiveCountries';
function Login() {
  const { countryCode } = useSelector((state) => state.auth);
  const history = useHistory();

  const searchedCountries = Countries?.find((l) => l.code === countryCode)

  if (countryCode) {
    if (ActiveCountries?.includes(countryCode)) {
      history.push(`/`)
    }
  }

  return (
    <div className="Alert__page">
      <div className="row Alert__message">
        <div className="col-md-6">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <h5 className="Alert__Heading" style={{ color: '#fe7f4a', fontSize: '1.2rem', fontWeight: 300 }}>
              This site is not available in {searchedCountries?.label}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
