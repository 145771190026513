import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Product'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: (data) => {
        return {
          url: `api/product/${data.id}?variant=${data.variant}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Store-Id': data.store_id,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Product'],
    }),

    getVarProduct: builder.query({
      query: (data) => {
        return {
          url: `api/product/variants/${data.id}?variant=${data.variant}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Store-Id': data.store_id,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Product'],
    }),

    similarProduct: builder.query({
      query: (data) => {
        return {
          url: `api/product/similar/${data.id}?name=${data.title}`,
          method: 'GET',
          headers: {
            'X-Country-Code': data.countryCode,
            'X-Store-Id': data.store_id,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Product'],
    }),

  }),

})

export const { useGetProductQuery, useGetVarProductQuery, useSimilarProductQuery } = productApi