import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import HelmetComponent from '../../../Components/HelmetComponent';
import SideMenu from '../SideMenu';
import { useGetUserDataQuery } from '../../../services/login';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

function AccountWallet() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, countryCode } = useSelector((state) => state.auth);
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <>
      <HelmetComponent title="Support" />
      <div className="Account-page">
        <div className="container">
          <div className='account-section'>
            <SideMenu />
            <div className='op-section' style={{ width: '100%' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-md-12">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AccountBalanceWalletOutlinedIcon style={{ fontSize: '1.5rem' }} />
                    <div className='ap-title' style={{ fontSize: '1.2rem', margin: '0px 10px' }}>
                      {t('Account Wallet')}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div style={{
                    padding: '30px',
                    background: '#fff',
                    border: '1px solid #e5e5e5',
                    borderRadius: '10px',
                    textAlign: 'center',
                    height: '140px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <div>
                      <div style={{ fontWeight: 600, marginBottom: '10px', fontSize: '1.2rem', fontWeight: 300 }}>Available Balance</div>
                      <div style={{ fontSize: '2rem' }}>{userData?.contant?.currency} 0</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountWallet;

