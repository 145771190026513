import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next' 
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from "../Pagination";
import { useSelector } from "react-redux";
function Shop() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const [getSearchId, setSearchId] = useState("")
  const { title, text } = useParams();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 24
 

 
  return (
    <div className="Search-page" style={{paddingTop: '4rem'}}>

      <div className="Lit-Banner">
        <div className="container">
          <div className="Lit-banner-card">
            <div className="row">
              <div className="col-md-12 Banner-text">
                <div className="Banner-text-style">
                  {/* <img src={`https://dj70nht8amdum.cloudfront.net/media/${languageCode}/${id}`} width="100%" /> */}
                  <div className="category-banner-text-section">
                    <div className="category-banner-text-card">
                      <h3>
                        <br /><br /><br /><br />
                        {t('coming_soon')} {title}
                        <br /><br /><br /><br />
                      </h3>
                      <p>{text === 'query' ? '' : text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   
 

    </div>
  );
}

export default Shop;

