import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import LoginForm from './LoginForm';

function LoginModal({ isSigninModal, signinModal }) {

  const { userId, userToken, userEmail, languageCode, countryCode, cartToken } = useSelector((state) => state.auth);
  let fontFamilyForAll

  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
    textAlign: 'start'
  }

  return (
    <>


      <Modal className="login-modal" show={isSigninModal} onHide={signinModal} centered animation={false}>
        <Modal.Body style={fontFamily}>

          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={signinModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>


          <LoginForm signinModal={signinModal} />
        </Modal.Body>
      </Modal>


    </>
  );
}

export default LoginModal;
